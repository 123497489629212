import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Warning } from '@material-ui/icons';

import { getAccessToken } from '../../../../helpers/functions/cookies';
import { enqueueSnackbar } from '../../../../redux/actions/notificationsActions';
import translation from '../../../../translation/translation';
import config from '../../../../config';
import store from '../../../../redux/store';

const styles = theme => ({
  root: {
    marginTop: 25
  },
  dialogContent: {
    margin: 0,
    padding: 0,
    outline: "none",
    height: "100%",
    lineHeight: 0,
    '&:first-child': {
      paddingTop: 0
    }
  },
  dialogActions: {
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    padding: "8px 4px",
    margin: 0
  },
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    fontSize: 20,
    fontWeight: 500
  },
  loaderWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    position: "absolute"
  },
  tablet: {
    display: "none",
    [theme.breakpoints.down('1025')]: {
      display: "inline-flex"
    }
  },
  desktop: {
    [theme.breakpoints.down('1025')]: {
      display: "none"
    }  
  }
});

class MediaTypeForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isConfirmCloseFormDialogOpen: false,
      isFormDialogOpen: false,
      isFrameLoading: false
    };

    this.handleOpenFormDialog = this.handleOpenFormDialog.bind(this);
    this.handleOpenConfirmCloseFormDialog = this.handleOpenConfirmCloseFormDialog.bind(this);
    this.handleCloseFormDialog = this.handleCloseFormDialog.bind(this);
    this.handleCloseConfirmCloseFormDialog = this.handleCloseConfirmCloseFormDialog.bind(this);
    this.handleLoad = this.handleLoad.bind(this);
    this.messageListener = this.messageListener.bind(this);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.messageListener, false);
  }

  messageListener(e) {
    if (!e) return;
  
    const {
      mediaType,
      onComplete
    } = this.props;

    /**
     * Maybe improve security later by check origin.
     */ 
    // const origin = e.originalEvent && e.originalEvent.origin || e.origin;

    // if (origin !== `${config.api_url}providers/self/order-products/${productId}/medias/forms/${mediaType.shortcode}/form`)
    //   return;
    
    if (e.data && e.data.message && e.data.message === "media_type_form_sent") {
      if (e.data.status && e.data.status === 200) {
        store.dispatch(enqueueSnackbar({
          message: translation().orders.media_groups.media_type_form.success,
          options: {
            variant: 'success',
          },
        }));
        this.handleCloseFormDialog();

        if (onComplete && mediaType.shortcode) {
          onComplete(mediaType.shortcode);
        }
      }
    }
  }

  handleOpenFormDialog() {
    this.setState({
      isFrameLoading: true,
      isFormDialogOpen: true
    });

    window.addEventListener('message', this.messageListener, false);
  }
  

  handleCloseFormDialog() {
    this.setState({
      isConfirmCloseFormDialogOpen: false,
      isFrameLoading: false,
      isFormDialogOpen: false
    });
  
    window.removeEventListener('message', this.messageListener, false);
  }

  handleOpenConfirmCloseFormDialog() {
    this.setState({ isConfirmCloseFormDialogOpen: true });
  }

  handleCloseConfirmCloseFormDialog() {
    this.setState({ isConfirmCloseFormDialogOpen: false });
  }

  handleLoad() {
    this.setState({ isFrameLoading: false });
  }

  render() {
    const {
      classes,
      mediaType,
      orderId,
      productId
    } = this.props;
    
    const {
      isConfirmCloseFormDialogOpen,
      isFormDialogOpen,
      isFrameLoading
    } = this.state;

    return (
      <div className={classes.root}>
        { mediaType.medias && mediaType.medias.length > 0
          ? (
            <div>
              <Button
                onClick={this.handleOpenFormDialog}
                color="primary"
                variant="contained"
                className={classes.desktop}
              >
                {translation().orders.media_groups.media_type_form.view}
              </Button>
              <Button
                color="primary"
                variant="contained"
                className={classes.tablet}
                component="a"
                target="_blank"
                href={`${config.api_url}orders/${orderId}/products/${productId}/medias/forms/${mediaType.shortcode}/form?access_token=${getAccessToken()}`}
                rel="noopener noreferrer"
              >
                {translation().orders.media_groups.media_type_form.view}
              </Button>
            </div>
          ) 
          : false
        }
        <Dialog
          disableBackdropClick
          fullWidth={true}
          maxWidth="xl"
          open={isFormDialogOpen}
          onClose={this.handleCloseFormDialog}
        >
          <DialogContent className={classes.dialogContent}>
            { isFrameLoading &&
              <div className={classes.loaderWrapper}>
                <CircularProgress size={50} color="secondary" />
              </div>
            }
            <iframe
              title={mediaType.name}
              width="100%"
              height="600"
              className="form-frame"
              frameBorder="0"
              allowFullScreen
              onLoad={this.handleLoad}
              src={`${config.api_url}orders/${orderId}/products/${productId}/medias/forms/${mediaType.shortcode}/form?access_token=${getAccessToken()}`}
            />
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              onClick={mediaType.medias && mediaType.medias.length > 0 ? this.handleCloseFormDialog : this.handleOpenConfirmCloseFormDialog}
              color="default"
            >
              {translation().orders.media_groups.media_type_form.close}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={isConfirmCloseFormDialogOpen}
          onClose={this.handleCloseConfirmCloseFormDialog}
        >
          <DialogTitle disableTypography className={classes.dialogTitle}>
            <Warning fontSize="small" style={{marginRight: 4}} /> {translation().orders.media_groups.media_type_form.close_dialog.title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {translation().orders.media_groups.media_type_form.close_dialog.description}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseConfirmCloseFormDialog}
              color="default"
            >
              {translation().orders.media_groups.media_type_form.close_dialog.cancel}
            </Button>
            <Button
              onClick={this.handleCloseFormDialog}
              variant="contained"
              color="primary"
            >
              {translation().orders.media_groups.media_type_form.close_dialog.confirm}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

MediaTypeForm.propTypes = {
  mediaType: PropTypes.shape({
    shortcode: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.number,
    medias: PropTypes.arrayOf(PropTypes.shape())
  }).isRequired,
  classes: PropTypes.shape().isRequired,
  orderId: PropTypes.number.isRequired,
  productId: PropTypes.number.isRequired,
  onComplete: PropTypes.func
};

export default withStyles(styles)(MediaTypeForm);
