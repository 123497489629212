import React, { Component } from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";

import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";

import translation from "../../../../translation/translation";

function SearchOrderInput({ input }) {
  return (
    <InputBase
      {...input}
      placeholder={translation().orders.search + "…"}
      className="search-input"
      autoComplete="off"
    />
  );
}

SearchOrderInput.propTypes = {
  input: PropTypes.shape().isRequired,
};

class SearchForm extends Component {
  render() {
    const { handleSubmit } = this.props;

    return (
      <form onSubmit={handleSubmit} autoComplete="off">
        <div className="search-input-root">
          <div className="search-icon">
            <SearchIcon />
          </div>
          <Field name="search" type="search" component={SearchOrderInput} />
        </div>
      </form>
    );
  }
}

SearchForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: "ordersSearchForm",
})(SearchForm);
