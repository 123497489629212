import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import Bowser from "bowser";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

import Authentication from "./components/authentication/Authentication";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Notifier from "./components/notifications/Notifier";

import OrderContainer from "./containers/order/OrderContainer";
import OrdersContainer from "./containers/orders/OrdersContainer";
import ProfileContainer from "./containers/profile/ProfileContainer";
import TasksContainer from "./containers/tasks/TasksContainer";

import PageNotFound from "./views/pageNotFound/PageNotFound";

import { getAccessToken } from "./helpers/functions/cookies";

import config from "./config";
import translation from "./translation/translation";

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: config.primary_color || "#FFD700",
      dark: "#fcbf13",
    },
    secondary: {
      main: config.secondary_color || "#F29D06",
    },
  },
  overrides: {
    ListItemText: {
      primary: {
        fontWeight: 500,
      },
    },
    MuiTableCell: {
      root: {
        padding: "4px 12px",
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: config.dark_color || "#1A2123",
        color: "#FFFFFF",
      },
      colorDefault: {
        backgroundColor: config.dark_color || "#1A2123",
        color: "#FFFFFF",
      },
    },
    MuiFab: {
      secondary: {
        backgroundColor: config.dark_color || "#1A2123",
        color: "white",
        "&:hover": {
          backgroundColor: "black",
        },
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: config.primary_color || "#FFD700",
      },
    },
    MuiSwitch: {
      switchBase: {
        color: config.primary_color || "#FFD700",
        "&$checked": {
          color: config.dark_color || "#1A2123",
        },
      },
    },
    MuiSnackbar: {
      root: {
        zIndex: 2100,
      },
    },
    MuiSnackbarContent: {
      message: {
        flex: 1,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 50,
        padding: "11px 18px",
        fontFamily: "Montserrat-Bold",
        fontWeight: "normal",
        textTransform: "none",
      },
      outlinedPrimary: {
        color: config.primary_color || "#FFD700",
        "&:hover": {
          color: config.primary_color || "#FFD700",
        },
      },
      containedPrimary: {
        "&$disabled": {
          backgroundColor: "#E0E0E0",
        },
      },
      containedSecondary: {
        backgroundColor: config.dark_color || "#1A2123",
        color: "#FFFFFF",
        "&:hover": {
          backgroundColor: "#000000",
        },
        "&$disabled": {
          backgroundColor: "#E0E0E0",
        },
      },
      outlined: {
        padding: "11px 18px",
      },
      contained: {
        boxShadow: "none",
      },
    },
    MuiIconButton: {
      colorInherit: {
        color: config.dark_color || "#1A2123",
      },
    },
    MuiDialog: {
      root: {
        zIndex: 2000,
      },
      paper: {
        margin: 15,
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: config.dark_color || "#1A2123",
        backgroundColor: "transparent",
        "&:hover": {
          backgroundColor: "#f1f1f1",
        },
      },
    },
  },
  error: {
    dark: "#f44336",
  },
});

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" component={OrdersContainer} />
      <Route exact path="/order-products" component={OrdersContainer} />
      <Route path="/orders/:oid/products/:pid" component={OrderContainer} />
      <Route path="/profile" component={ProfileContainer} />
      <Route path="/tasks" component={TasksContainer} />
      <Route path="*" component={PageNotFound} />
      <Redirect from="*" to="/page-not-found" />
    </Switch>
  );
};

class App extends Component {
  componentWillMount() {
    this.props.getUser();

    /**
     * Check if Internet Explorer is used as user agent. If yes, alert user to user another modern browser.
     */
    const browser = Bowser.getParser(window.navigator.userAgent);
    if (browser) {
      const devicePlatform = browser.getBrowserName();
      if (devicePlatform && devicePlatform === "Internet Explorer") {
        alert(translation().commons.do_not_use_IE);
      }
    }
  }

  render() {
    const { reduxStore, removeSnackbar, signOut } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        <Router>
          <div className="App">
            {reduxStore.user.loading && (
              <LinearProgress className="App-progress" />
            )}
            {reduxStore && reduxStore.user.data ? (
              <div className="App-router">
                <Header signOut={signOut} user={reduxStore.user.data} />
                <Routes {...this.props} />
                <Footer />
              </div>
            ) : (
              !getAccessToken() && <Authentication {...this.props} />
            )}
            <div id="iframes-container" />
            <Notifier
              notifications={reduxStore.app.notifications}
              removeSnackbar={removeSnackbar}
            />
          </div>
        </Router>
      </MuiThemeProvider>
    );
  }
}

App.propTypes = {
  getUser: PropTypes.func.isRequired,
  reduxStore: PropTypes.shape({
    user: PropTypes.shape().isRequired,
    app: PropTypes.shape().isRequired,
  }),
  removeSnackbar: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
};

export default App;
