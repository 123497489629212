import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import CircularProgress from '@material-ui/core/CircularProgress';

import { isObjEmpty } from '../../../helpers/functions/utils';
import {
  getAccessToken,
  getLocale
} from '../../../helpers/functions/cookies';

import config from '../../../config';

import AutocompleteField from './AutocompleteField';

class ApiAutocompleteComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
      loading: false
    }

    this._source = undefined;
    this.autoCompleteFunction = this.autoCompleteFunction.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSelectValue = this.handleSelectValue.bind(this);
  }

  autoCompleteFunction(route, query = {}, data = {}, headers = {}) {

    const {
      onRequestSuccess,
      onRequestFailure,
    } = this.props;

    /**
     * Cancel the previous request
     */
    if (typeof this._source !== typeof undefined)
      this._source.cancel();

    /**
     * Save the new request for cancellation
     */
    this._source = axios.CancelToken.source();

    if (!route) {
      throw new Error("Route not found");
    }

    if (!query) {
      query = {};
    }

    if (!data) {
      data = {};
    }

    if (!headers) {
      headers = {};
    }

    const axiosRequest = {
      method: "get",
      cancelToken: this._source.token
    };

    const accessToken = getAccessToken();

    if (accessToken)
      query.access_token = accessToken;
    else
      query.client_id = config.api_key;

    const locale = getLocale();

    if (locale)
      query.locale = locale;

    /**
     * No need
     */
    /*
      const currency = getCurrency();

      if (currency)
        query.currency = currency;

      const timezone = getTimezone();

      if (timezone)
        query.timezone = timezone;
    */

    if (route) {
      if (route.match(config.api_url))
        route = route.replace(config.api_url, "");
      axiosRequest.url = config.api_url + route;
    }

    if (!isObjEmpty(query))
      axiosRequest.params = query;

    if (!isObjEmpty(data))
      axiosRequest.data = data;

    if (!isObjEmpty(headers))
      axiosRequest.headers = headers;

    this.setState({ loading: true });

    axios(axiosRequest)
      .then((response) => {
        const responseData = response.data.data;
        const paging = response.data.paging;

        this.setState({
          dataSource: responseData,
          dataSourcePaging: paging,
          loading: false
        });

        if (onRequestSuccess && typeof onRequestSuccess === 'function')
          onRequestSuccess(response);
      })
      .catch((thrown) => {
        if (onRequestFailure && typeof onRequestFailure === 'function')
          onRequestFailure(thrown);
      });
  }

  handleInputChange(value) {

    if (!value)
      return;

    const {
      input,
      onInputChange,
      dataSourceRoute,
      dataSourceParams
    } = this.props;

    if (input && input.onChange)
      input.onChange(value);

    this.autoCompleteFunction(dataSourceRoute, { search: value, ...dataSourceParams });

    if (onInputChange)
      onInputChange(value);
  }

  handleSelectValue(value) {

    const { onSelectValue } = this.props;

    if (onSelectValue)
      onSelectValue(value);
  }

  render () {

    const {
      defaultValue,
      isDisabled,
      isClearable,
      placeholder,
      name
    } = this.props;

    const {
      dataSource,
      loading
    } = this.state;

    return (
      <>
        <AutocompleteField
          name={name}
          defaultValue={defaultValue}
          suggestions={dataSource}
          isDisabled={isDisabled}
          placeholder={placeholder}
          isClearable={isClearable}
          onInputChange={this.handleInputChange}
          onSelectValue={this.handleSelectValue}
        />
        {
          loading &&
          <CircularProgress
            size={25}
            thickness={3}
            color="secondary"
            className="autocomplete-loader"
          />
        }
      </>
    );
  }
}

ApiAutocompleteComponent.defaultProps = {
  isDisabled: false,
  dataSourceParams: {}
}

ApiAutocompleteComponent.propTypes = {
  defaultValue: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.arrayOf(PropTypes.shape())
  ]),
  input: PropTypes.any,
  isClearable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onRequestSuccess: PropTypes.func,
  onRequestFailure: PropTypes.func,
  onInputChange: PropTypes.func,
  onSelectValue: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  dataSourceRoute: PropTypes.string.isRequired,
  dataSourceParams: PropTypes.shape(),
}

export default ApiAutocompleteComponent;
