import { unescape } from 'lodash';

import translation from '../../translation/translation';

/**
 * Add three dots for long text
 */
export const addThreeDots = (string, limit) => {
  const dots = "...";

  if (!string || !limit)
    return;

  if (string.length > limit) {
    string = string.substring(0, limit) + dots;
  }
  return string;
}

/**
 * Function that take url for compose an object
 */
export const parseUrl = url => {

  if (!url)
    return;

  /**
   * Decode special char like &amp; to &
   */
  url = unescape(decodeURIComponent(url));

  const parts = {};
  let hasDomain = true;

  if (url.indexOf('://') < 0)
    hasDomain = false;

  parts.scheme = (hasDomain ? url.split('://').shift() : '' );
  parts.host = (hasDomain ? url.split('://').pop().split('/').shift() : '' );
  parts.route = (hasDomain && (url.indexOf('/') > 0) ? url.split(parts.host + '/').pop().split('?').shift() : url.split(parts.host + '/').split('?').shift());
  parts.queryString = (url.indexOf('?') > 0 ? url.split('?').pop().split('#').shift() : '');
  parts.fragment = (url.indexOf('#') > 0 ? url.split('#').pop() : '');

  let query_split = parts.queryString.split('&');
  parts.query = {};

  for (let i = 0; i < query_split.length; i++) {
    const eq = query_split[i].split('=');
    parts.query[eq[0]] = eq[1];
  }

  return parts;
}

/**
 * Function that take object url for compose an URL
 */
export const composeUrl = parts => {

  if (!parts)
    return;

  let queryEqs = [];
  for (let key in parts.query) {
    queryEqs.push(key + '=' + parts.query[key]);
  }

  const url = (parts.scheme ? parts.scheme + '://' : '' ) + (parts.host ? parts.host + '/' : '') + parts.route + (parts.query ?  '?' + queryEqs.join('&') : '') + (parts.fragment ? '#' + parts.fragment : '');

  return url;
}

/**
 * Count object properties
 */
export const countObjProps = obj => {

  if (!obj)
    return;

  let count = 0;

  for (let i in obj) {
    if (obj.hasOwnProperty(i))
      count++;
  }

  return count;
}

/**
 * check if object is empty
 */
export const isObjEmpty = obj => {
  if (obj) {
    return (!countObjProps(obj));
  }
}

/**
 * Generate random number
 * used for session for example
 */
export const generateRandom = (min, max) => {

  if (min && max) {
    const randomNbr = Math.floor(Math.random() * (max - min) + min);
    return randomNbr;
  }
}

/**
 * Hash a string
 */
 export const generateHash = string => {

  let hash = 0;

  if (string.length === 0)
    return hash;

  for (let i = 0; i < string.length; i++) {
    let char = string.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash;
  }

  return hash;
}

/**
 * Map an object as an array
 */
export const objectMapToArray = (object, callback) => {

  if (typeof callback !== 'function' || !object)
    return;

  let resultArray = [];
  let index = 0;

  for (let prop in object) {
    resultArray.push(callback(prop, object[prop], index, object));
    index++;
  }

  return resultArray;
}

/**
 * Check if value is an integer
 */
export const isInteger = value => {

  const regExpInt=  /^-?\d+\.?\d*$/;

  if (value.toString().match(regExpInt))
    return true;
  else
    return false;
}

/**
 * Check if value is a float
 */
export const isFloat = value => {
  const regExpFloat = /^[-+][0-9]+\.[0-9]+[eE][-+]?[0-9]+$/;

  if (isInteger(value)) {
    return true;
  }
  else if (value.toString().match(regExpFloat)) {
    return true;
  }
  else {
    return false;
  }
}

/**
 * Add zero before a number if he is < 10
 */
export const pad = n => {

  n = parseInt(n, 10);

  return (n < 10) ? ("0" + n) : n;
}

/**
 * Format bytes
 */
export const formatBytes = (bytes, decimals) => {

 if (bytes === 0) return;

  const k = 1000;
  const dm = decimals || 2;
  const sizes = [translation().commons.bytes, 'Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const formatedBytes = parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];

  return formatedBytes;
}

/**
 * Check if a string is a valid URL
 * Source : https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
 */
export const isStringUrl = string => {

  const pattern = new RegExp('^(https?:\\/\\/)?' +
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' +
  '((\\d{1,3}\\.){3}\\d{1,3}))' +
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
  '(\\?[;&a-z\\d%_.~+=-]*)?' +
  '(\\#[-a-z\\d_]*)?$','i');

  return pattern.test(string);
}


/**
 * Query object to query string
 */
export const stringifyQuery = (query, encodeUrl = true) => {

  if (!query || isObjEmpty(query))
    return;

  let queryStringParts = [];

  for (let key in query) {

    const queryKey = key;
    let queryValue = "";

    if (typeof query[key] === ("object" || "array"))
      queryValue = JSON.stringify(query[key]);
    else
      queryValue = query[key].toString();

    queryStringParts.push((encodeUrl ? encodeURIComponent(queryKey) : queryKey) + "=" + (encodeUrl ? encodeURIComponent(queryValue) : queryValue));
  }

  return queryStringParts.join("&");
}

/**
 * Function that use address google autocomplete component for build property object
 */
export const googleMapsAddressMapper = addressObject => {

  if (!addressObject) return null;

  if (addressObject.address_components) {
    const addressComponents = addressObject.address_components;
    let addressSelected = {};

    for (let i = 0; i < addressComponents.length; i++) {
      if (addressComponents[i] && addressComponents[i].types && addressComponents[i].types.length) {
        for (let j = 0; j < addressComponents[i].types.length; j++) {
          addressSelected[addressComponents[i].types[j]] = {};

          if (addressComponents[i].long_name)
            addressSelected[addressComponents[i].types[j]]['long_name'] = addressComponents[i].long_name;

          if (addressComponents[i].short_name)
            addressSelected[addressComponents[i].types[j]]['short_name'] = addressComponents[i].short_name;
        }
      }
    }

    /**
    * UK tricks, if no locality found, replace it by postal_town
    */
    if (!addressSelected.locality && addressSelected.postal_town) {
      addressSelected.locality = addressSelected.postal_town;
    }

    return addressSelected;
  }
  else return false;
}

export const getDeviceBrand = () => {

  let deviceBrand = "Unknown";

  if (window.navigator.userAgent.indexOf("Windows NT 10.0") !== -1) deviceBrand = "Windows 10";
  if (window.navigator.userAgent.indexOf("Windows NT 6.2")  !== -1) deviceBrand = "Windows 8";
  if (window.navigator.userAgent.indexOf("Windows NT 6.1")  !== -1) deviceBrand = "Windows 7";
  if (window.navigator.userAgent.indexOf("Windows NT 6.0")  !== -1) deviceBrand = "Windows Vista";
  if (window.navigator.userAgent.indexOf("Windows NT 5.1")  !== -1) deviceBrand = "Windows XP";
  if (window.navigator.userAgent.indexOf("Windows NT 5.0")  !== -1) deviceBrand = "Windows 2000";
  if (window.navigator.userAgent.indexOf("Mac")             !== -1) deviceBrand = "Apple";
  if (window.navigator.userAgent.indexOf("X11")             !== -1) deviceBrand = "Unix";
  if (window.navigator.userAgent.indexOf("Linux")           !== -1) deviceBrand = "Linux";

  return deviceBrand;
}

export const getApiConfigFromLocation = key => {

  const envs = {
    "PROD": {
      api_url: "https://api.wall-market.com/v1/",
      api_key: "6232a5deadb46d42326941e684561921",
      name: "prod"
    },
    "SANDBOX": {
      api_url: "https://api.sandbox.wall-market.com/v1/",
      api_key: "f94900b20ecdcc067311037dd9cbe483",
      name: "sandbox"
    },
    "DEV": {
      api_url: "https://api.dev-wall-market.com/v1/",
      api_key: "f94900b20ecdcc067311037dd9cbe483",
      name: "dev"
    },
    "LOCAL": {
      api_url: "https://api.dev-wall-market.com/v1/",
      api_key: "574fd53a5765033cedc78e95687dc832",
      name: "local"
    }
  };

  const locations = {
    "PROD": "finishers-lightshop.wall-market.com",
    "SANDBOX": "finishers-lightshop.sandbox.wall-market.com",
    "DEV": "finishers-lightshop.dev-wall-market.com",
    "LOCAL": "localhost"
  };

  if (window && window.location && window.location.hostname) {
    for (let prop in locations) {
      if (window.location.hostname === locations[prop]) {
        return envs[prop][key];
      }
    }
  }
  else {
    return envs["LOCAL"][key];
  }
}