const initialState = {
  notifications: {
    key: 1,
    message: null
  },
};

export default (state = initialState, action) => {

  switch (action.type) {
    case 'CLEAR_REDUCERS':
      return initialState;
    case 'ENQUEUE_SNACKBAR':
      return {
        ...state,
        notifications: {
          ...action.notification,
        }
      };
    case 'REMOVE_SNACKBAR':
      return {
        ...state,
        notifications: initialState.notifications
      }
    default:
      return state;
  }
};
