import React, { Component } from "react";
import PropTypes from "prop-types";

import CircularProgress from "@material-ui/core/CircularProgress";

import config from "../../../../config";
import translation from "../../../../translation/translation";
import MediaGroup from "./MediaGroup";
import MediaGroupForm from "./MediaGroupForm";

function Media({ media }) {
  function handleDownload() {
    const url = media.url + "/download?client_id=" + config.api_key;

    const downloadIframe = document.getElementById("frame-download");
    const iframeContainer = document.getElementById("iframes-container");

    if (downloadIframe) downloadIframe.setAttribute("src", url);
    else {
      const downloadIframe =
        '<iframe id="frame-download" title="iframe-download" width="0" height="0" style="display: none" src=' +
        url +
        " />";
      iframeContainer.innerHTML = downloadIframe;
    }
  }

  let imgPreviewStyle = {};

  if (media.thumbnail_url)
    imgPreviewStyle = {
      backgroundImage:
        'url("' +
        (media.thumbnail_url.indexOf("?client_id=" + config.api_key) !== -1
          ? media.thumbnail_url + "?client_id=" + config.api_key
          : media.thumbnail_url) +
        '")',
    };
  else if (media.extension)
    imgPreviewStyle = {
      backgroundImage:
        'url("/assets/images/medias/file-extension-' +
        media.extension +
        '.png")',
    };
  else return false;

  return (
    <div className="prod-media">
      <div className="img-preview" style={imgPreviewStyle}>
        <div className="media-actions">
          {/* eslint-disable-next-line react/jsx-no-bind */}
          <div className="icon-download action-icon" onClick={handleDownload} />
        </div>
      </div>
    </div>
  );
}

Media.propTypes = {
  media: PropTypes.shape().isRequired,
};

class MediaGroups extends Component {
  componentWillMount() {
    const {
      getProductMediaGroups,
      product: { order_id, id },
    } = this.props;

    getProductMediaGroups(order_id, id);
  }

  componentWillUnmount() {
    const { clearMediaGroups } = this.props;

    clearMediaGroups();
  }

  renderMediaGroups({ isLoading, list }, product) {
    if (!list || !product || !product.id) return;

    let mediaGroups = [];
    const { downloadOrderProductMedias } = this.props;

    if (list.length > 0) {
      for (let i = 0; i < list.length; i++) {
        mediaGroups.push(
          <MediaGroup
            key={i}
            downloadOrderProductMedias={downloadOrderProductMedias}
            mediaGroup={list[i]}
            productId={product.id}
            orderId={product.order_id}
          />
        );
      }
    } else if (isLoading) {
      mediaGroups.push(
        <div key={0} className="center-loader">
          <CircularProgress color={"secondary"} size={35} />
        </div>
      );
    }

    return mediaGroups;
  }

  renderFormMediaGroup({ isLoading, list }, product) {
    if (!list || !product || !product.id || !product.order_id) return;

    let mediaGroups = [];

    if (list.length > 0) {
      for (let i = 0; i < list.length; i++) {
        mediaGroups.push(
          <MediaGroupForm
            key={i}
            mediaGroup={list[i]}
            productId={product.id}
            orderId={product.order_id}
          />
        );
      }
    } else if (isLoading) {
      mediaGroups.push(
        <div key={0} className="center-loader">
          <CircularProgress color="secondary" size={35} />
        </div>
      );
    }

    return mediaGroups;
  }

  render() {
    const {
      product,
      mediaGroups: { isLoading, list },
    } = this.props;

    return (
      <div className="Media-Groups">
        {this.renderFormMediaGroup({ isLoading, list }, product)}
        {product && product.id ? (
          this.renderMediaGroups({ isLoading, list }, product)
        ) : (
          <div className="no-uploadable">
            <p>
              <span className="icon-infos" />{" "}
              {translation().orders.content_not_done}
            </p>
          </div>
        )}
      </div>
    );
  }
}

MediaGroups.propTypes = {
  clearMediaGroups: PropTypes.func.isRequired,
  downloadOrderProductMedias: PropTypes.func.isRequired,
  getProductMediaGroups: PropTypes.func.isRequired,
  mediaGroups: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape()),
    isLoading: PropTypes.bool
  }),
  product: PropTypes.shape({
    id: PropTypes.any.isRequired,
    order_id: PropTypes.number,
    uploadable: PropTypes.bool,
  }),
};

export default MediaGroups;
