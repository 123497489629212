export const updateProfile = () => ({
  type: "UPDATE_INFORMATIONS"
});

export const updateProfileSuccess = data => ({
  type: "UPDATE_INFORMATIONS_SUCCESS",
  data
});

export const updateProfileFailure = error => ({
  type: "UPDATE_INFORMATIONS_FAILURE",
  error
});

export const updatePassword = () => ({
  type: "UPDATE_PASSWORD"
});

export const updatePasswordSuccess = data => ({
  type: "UPDATE_PASSWORD_SUCCESS",
  data
});

export const updatePasswordFailure = error => ({
  type: "UPDATE_PASSWORD_FAILURE",
  error
});

export const updateSettings = () => ({
  type: "UPDATE_SETTINGS"
});

export const updateSettingsSuccess = data => ({
  type: "UPDATE_SETTINGS_SUCCESS",
  data
});

export const updateSettingsFailure = error => ({
  type: "UPDATE_SETTINGS_FAILURE",
  error
});
