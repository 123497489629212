/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, formValueSelector, change } from 'redux-form';

import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MuiSelectField from './MuiSelectField';

import {
  dateInTz,
  getCurrentDate,
  addToDate,
  formatDate,
  createDateUtc,
  createDateFromArray,
  removeFromDate
} from '../../../helpers/functions/dates';

import store from '../../../redux/store';

function FieldHook(props) {

  const {
    input,
    meta: { touched, error }
  } = props;

  return (
      <>
        <input
          {...input}
          type="hidden"
          style={{height: 0}}
          id="dateOfBirth"
        />
        { touched && error && <FormHelperText error>{error}</FormHelperText> }
      </>
  )
}

FieldHook.propTypes = {
  input: PropTypes.shape({}),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.any
  })
}

const compileBirthday = (form, type, value) => {
  /**
   * Set value from input
   * If no value we check in redux form values
   */
  const state = store.getState();
  const selector = formValueSelector(form);
  const reduxYear = selector(state, 'birthdayYear');
  const reduxMonth = selector(state, 'birthdayMonth');
  const reduxDay = selector(state, 'birthdayDay');

  const year = (type && type === "year") && value ? value : reduxYear ? reduxYear : null;
  const month = (type && type === "month") && value ? value : reduxMonth ? reduxMonth : null;
  const day = (type && type === "day") && value ? value : reduxDay ? reduxDay : null;

  if (!year || !month || !day) return;

  const birthday = createDateFromArray([year, month, day]);

  /**
   * Set new birthday to redux
   */
  store.dispatch(change(form, 'date_of_birth', formatDate(birthday, 'YYYY-MM-DD')));
}

class BirthdateField extends Component {

  constructor(props) {
    super(props);

    this.state = {
      date: getCurrentDate(),
      days: [],
      months: [],
      years: []
    }
  }

  componentWillMount() {

    const nowTz = dateInTz(this.state.date);
    const monthsArray = [];
    const yearsArray = [];
    let daysArray = [];

    /**
     * Create months
     */
    for (let i = 0; i < 12; i++) {
      let months = addToDate(nowTz, i, 'months', null);
      monthsArray.push(months);
    }

    /**
     * Create daysArray
     */
    for (let i = 1; i < 32; i++) {
      daysArray[i] = i;
    }

    /**
     * Create years
     */
    for (let i = 0; i < 100; i++) {
      const currentYear = createDateUtc();
      let years = removeFromDate(currentYear, i, 'years', 'YYYY');
      yearsArray.push(years);
    }

    /**
     * Pushing to component state
     */
    this.setState({
      months: monthsArray,
      days: daysArray,
      years: yearsArray
    });
  }

  render() {

    const {
      formName,
      label
    } = this.props;

    const {
      months,
      years,
      days,
    } = this.state;

    return (
      <div>
        <InputLabel style={{marginBottom: 5, display: 'block', fontSize: 13}}>{label}</InputLabel>
        <div className="row">
          <div className="col-xs-4 col-sm-4 col-md-3 birth-date-col">
            <Field
              name="birthdayDay"
              fullWidth={true}
              native={true}
              component={MuiSelectField}
              onChange={(event, value) => {
                compileBirthday(formName, "day", value);
              }}
            >
              <option value="" disabled />
              { days && days.map((day, index) => {
                  return <option key={index} value={day}>{day}</option>
                })
              }
            </Field>
          </div>
          <div className="col-xs-4 col-sm-4 col-md-5 birth-date-col">
            <Field
              name="birthdayMonth"
              component={MuiSelectField}
              fullWidth={true}
              native={true}
              onChange={(event, value) => {
                compileBirthday(formName, "month", value);
              }}
            >
              <option value="" disabled />
              { months && months.map((month, index) => {
                  return <option key={index} value={formatDate(month, "M")}>{formatDate(month, "MMMM")}</option>
                })
              }
            </Field>
          </div>
          <div className="col-xs-4 col-sm-4 col-md-4 birth-date-col">
            <Field
              name="birthdayYear"
              component={MuiSelectField}
              fullWidth={true}
              native={true}
              onChange={(event, value) => {
                compileBirthday(formName, "year", value);
              }}
            >
              <option value="" disabled />
              { years && years.reverse().map((year, index) => {
                  return <option key={index} value={year}>{year}</option>
                })
              }
            </Field>
          </div>
          <div className="col-xs-12">
            <Field
              name="date_of_birth"
              component={FieldHook}
            />
          </div>
          <div className="clearfix"/>
        </div>
      </div>
    );
  }
}

BirthdateField.propTypes = {
  formName: PropTypes.string.isRequired,
  label: PropTypes.string,
}

export default BirthdateField;
