export const storeUser = data => ({
  type: "STORE_USER",
  data
});

export const authUser = () => ({
  type: "AUTH_USER"
});

export const authUserSuccess = () => ({
  type: "AUTH_USER_SUCCESS"
});

export const authUserFailure = error => ({
  type: "AUTH_USER_FAILURE",
  error
});

export const getUser = () => ({
  type: "GET_USER"
});

export const getUserSuccess = data => ({
  type: "GET_USER_SUCCESS",
  data
});

export const getUserFailure = error => ({
  type: "GET_USER_FAILURE",
  error
});

export const removeUser = () => ({
  type: "REMOVE_USER"
});

export const registerUser = () => ({
  type: "REGISTER_USER"
});

export const registerUserSuccess = data => ({
  type: "REGISTER_USER_SUCCESS",
  data
});

export const registerUserFailure = error => ({
  type: "REGISTER_USER_FAILURE",
  error
});
