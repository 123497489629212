import React from 'react';

import config from '../../config';
import translation from '../../translation/translation';

const Footer = () => {
  return (
    <footer className="Footer" id="AppFooter">
      <p>Currently {config.site_version} - &copy; {new Date().getFullYear()} {translation().footer.copyright}</p>
    </footer>
  );
}

export default Footer;
