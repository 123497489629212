const initialState = {
  data: [],
  error: false,
  loading: false,
  paging: null,
  completion_statuses: {
    data: null,
    loading: false,
    error: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "CLEAR_REDUCERS":
      return initialState;
    case "CLEAR_TASKS":
      return initialState;
    case "GET_COMPLETION_STATUSES":
      return {
        ...state,
        completion_statuses: {
          loading: true,
          data: null,
          error: null,
        },
      };
    case "GET_COMPLETION_STATUSES_SUCCESS":
      return {
        ...state,
        completion_statuses: {
          loading: false,
          data: action.data,
          error: null,
        },
      };
    case "GET_COMPLETION_STATUSES_ERROR":
      return {
        ...state,
        completion_statuses: {
          ...state.completion_statuses,
          loading: false,
          error: action.error,
        },
      };
    case "REFRESH_TASKS":
      return {
        ...state,
        data: initialState.data,
        paging: initialState.paging,
        loading: true,
        error: false,
      };
    case "GET_TASKS":
      return {
        ...state,
        data: action.next ? state.data : initialState.data,
        paging: action.search ? initialState.paging : state.paging,
        loading: true,
        error: false,
      };
    case "GET_TASKS_SUCCESS": {
      const dataConcatenated = state.data.concat(action.data);

      return {
        ...state,
        data: dataConcatenated,
        paging: action.paging ? action.paging : state.paging,
        loading: false,
        error: null,
      };
    }
    case "GET_TASKS_ERROR":
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};
