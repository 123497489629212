/* eslint-disable react/jsx-no-bind */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  ButtonBase,
  CircularProgress,
  MenuItem,
  Select,
} from "@material-ui/core";

const styles = () => ({
  loader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  completionStatus: {
    position: "absolute",
    top: 0,
    left: 0,
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    willChange: "color",
    width: "100%",
    height: "100%",
    border: 0,
    borderRadius: 0,
    cursor: "pointer",
    "&:not(.no-hover):hover": {
      opacity: "0.65",
    },
  },
  statusMenuItem: {
    color: "white",
    "&:not(.no-hover):hover": {
      opacity: "0.65",
    },
  },
});

function CompletionStatus(props) {
  const { classes, onChange, statuses, taskId, value } = props;

  const [innerValue, setInnerValue] = useState(value);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  function handleOpen() {
    setOpen(true);
  }

  function handleChange(status) {
    if (!taskId || !status || !status.id) return;

    /**
     * Check if value changed, if not return;
     */
    if (innerValue && innerValue.id && innerValue.id === status.id) return;

    setLoading(true);
    onChange(
      taskId,
      status.id,
      (success) => {
        setLoading(false);
        setInnerValue(
          success && success.completion_status
            ? success.completion_status
            : status
            ? status
            : innerValue
        );
      },
      () => {
        setInnerValue(value);
        setLoading(false);
      }
    );
  }

  function handleBlur() {
    setOpen(false);
  }

  function handleChangeSelectStatus(e) {
    setOpen(false);
    handleChange(e.target.value);
  }

  return loading ? (
    <div className={classes.loader}>
      <CircularProgress size={30} />
    </div>
  ) : (
    <div>
      {open ? (
        <Select
          open
          value={innerValue ? innerValue.id : ""}
          onChange={handleChangeSelectStatus}
          label={"placeholder"}
          onClose={handleBlur}
          style={{ opacity: 0 }}
          MenuProps={{ MenuListProps: { style: { padding: 0 } } }}
        >
          {statuses && statuses.length > 0 ? (
            statuses.map((el, index) => (
              <MenuItem
                key={index}
                value={el}
                style={{
                  backgroundColor: el && el.color ? el.color : "",
                }}
                className={classes.statusMenuItem}
              >
                {el.name}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>No status found.</MenuItem>
          )}
        </Select>
      ) : (
        <ButtonBase
          className={classes.completionStatus}
          onClick={handleOpen}
          style={{
            backgroundColor:
              innerValue && innerValue.color ? innerValue.color : "",
          }}
        >
          {innerValue.name}
        </ButtonBase>
      )}
    </div>
  );
}

CompletionStatus.propTypes = {
  classes: PropTypes.shape(),
  onChange: PropTypes.func.isRequired,
  taskId: PropTypes.number.isRequired,
  statuses: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  value: PropTypes.shape({
    color: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
  }),
};

export default withStyles(styles)(CompletionStatus);
