import { compose, createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { reducer as reduxFormReducer } from "redux-form";
import { composeWithDevTools } from "redux-devtools-extension";

import appReducer from "./reducers/appReducer";
import authReducer from "./reducers/authReducer";
// import metricsReducer from './reducers/metricsReducer';
import orderReducer from "./reducers/orderReducer";
import ordersReducer from "./reducers/ordersReducer";
import {
  orderProductMediasStatusReducer,
  orderProductMediasReducer,
} from "./reducers/orderProductMediasReducer";
import userReducer from "./reducers/userReducer";
// import availabilitiesReducer from './reducers/availabilitiesReducer';
// import invoicesReducer from './reducers/invoicesReducer';
import profileReducer from "./reducers/profileReducer";
// import providerReducer from './reducers/providerReducer';
// import scheduleReducer from './reducers/scheduleReducer';
// import companyReducer from './reducers/companyReducer';
// import registerReducer from './reducers/registerReducer';
// import requestDispatchReducer from './reducers/requestDispatchReducer';
import tasksReducer from "./reducers/tasksReducer";

import config from "../config";

const reducers = combineReducers({
  app: appReducer,
  authentication: authReducer,
  user: userReducer,
  order: orderReducer,
  orders: ordersReducer,
  orderProductMedias: orderProductMediasReducer,
  orderProductMediasStatus: orderProductMediasStatusReducer,
  // availabilities: availabilitiesReducer,
  // invoices: invoicesReducer,
  profile: profileReducer,
  tasks: tasksReducer,
  // provider: providerReducer,
  // schedule: scheduleReducer,
  // company: companyReducer,
  // register: registerReducer,
  // requestDispatch: requestDispatchReducer,
  // metrics: metricsReducer,
  form: reduxFormReducer,
});

const composeEnhancers =
  config.env_mode.toLowerCase() === "prod" ? compose : composeWithDevTools;

const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));

export default store;
