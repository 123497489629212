/**
 * TASKS ACTIONS
 */
const clearTasks = () => ({
  type: "CLEAR_TASKS",
});

const getTaskViews = () => ({
  type: "GET_TASK_VIEWS",
});

const refreshTasks = () => ({
  type: "REFRESH_TASKS",
});

const getTasks = (next, search) => ({
  type: "GET_TASKS",
  next,
  search,
});

const getTasksSuccess = (data, paging) => ({
  type: "GET_TASKS_SUCCESS",
  data,
  paging,
});

const getTasksError = (error) => ({
  type: "GET_TASKS_ERROR",
  error,
});

const getCompletionStatuses = () => ({
  type: "GET_COMPLETION_STATUSES",
});

const getCompletionStatusesSuccess = (data) => ({
  type: "GET_COMPLETION_STATUSES_SUCCESS",
  data,
});

const getCompletionStatusesError = (error) => ({
  type: "GET_COMPLETION_STATUSES_ERROR",
  error,
});

export {
  clearTasks,
  getCompletionStatuses,
  getCompletionStatusesSuccess,
  getCompletionStatusesError,
  getTasks,
  getTasksError,
  getTasksSuccess,
  getTaskViews,
  refreshTasks,
};
