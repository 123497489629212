import React from 'react';
import { Link } from "react-router-dom";

import Button from '@material-ui/core/Button';

import translation from '../../translation/translation';

const HomeLink = props =>
  <Link to={"/"} {...props} />

const PageNotFound = () => {
  return (
    <div className="Page-not-found">
      <div className="container">
        <img src="/assets/images/commons/link-not-found.png" alt="404" />
        <h1>{translation().pages.page_not_found.title}</h1>
        <h2>{translation().pages.page_not_found.sub_title}</h2>
        <p>{translation().pages.page_not_found.text}</p>
        <Button
          variant="contained"
          color="default"
          component={HomeLink}
        >
          {translation().pages.page_not_found.button}
        </Button>
      </div>
    </div>
  )
}

export default PageNotFound;
