import React from "react";
import PropTypes from "prop-types";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

const renderOptions = (options) =>
  options.map((option, index) => {
    return (
      <FormControlLabel
        key={index}
        value={option.value}
        control={<Radio color="default" />}
        label={option.label}
        labelPlacement="start"
      />
    );
  });

function MuiRadioGroupField(props) {
  const {
    input,
    meta: { touched, error },
    label,
    row,
    options,
  } = props;

  return (
    <FormControl component="fieldset">
      <FormLabel focused={false} style={{ fontSize: 13 }}>
        {label}
      </FormLabel>
      <RadioGroup
        row={row}
        aria-label={label}
        {...input}
        style={{ marginLeft: "-14px" }}
      >
        {options && options.length > 1 ? renderOptions(options) : ""}
      </RadioGroup>
      {touched && error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
}

MuiRadioGroupField.defaultProps = {
  row: true,
};

MuiRadioGroupField.propTypes = {
  row: PropTypes.bool,
  label: PropTypes.any,
  meta: PropTypes.shape(),
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

export default MuiRadioGroupField;
