import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import config from "../../config";

import { fetchApi } from "../../helpers/functions/api";
import { enqueueSnackbar } from "../../redux/actions/notificationsActions";
import {
  getOrder,
  getOrderSuccess,
  getOrderFailure,
  getProductMediaGroups,
  getProductMediaGroupsSuccess,
  getProductMediaGroupsFailure,
  postOrderProductIdMedias,
  postOrderProductIdMediasSuccess,
  postOrderProductIdMediasFailure,
} from "../../redux/actions/orderActions";
import { clearMediaGroups } from "../../redux/actions/ordersActions";
import translation from "../../translation/translation";
import Order from "../../views/order/Order";

const mapStateToProps = (state) => ({
  user: state.user,
  order: state.order,
  orderProductMedias: state.orderProductMedias,
});

const mapDispatchToProps = (dispatch) => {
  const checkBeforeSubmit = (mediasOrderArray) => {
    /**
     * Check if all media templates are set
     */
    for (let i = 0; i < mediasOrderArray.length; i++) {
      if (
        mediasOrderArray[i]["media_template_id"] !== undefined &&
        mediasOrderArray[i].media_template_id === "null"
      )
        return false;
    }
    return true;
  };

  const getProviderPermission = (user) => {
    if (config.providerProductsMatches[user.id]) {
      const productIds = config.providerProductsMatches[user.id].join("/");

      return `product_id|in|${productIds},`;
    } else if (user.level && user.level.power && user.level.power >= 200) {
      return "";
    } else {
      return 403;
    }
  };

  const getProviderOrder = (user, oid, pid) => {
    if (!oid || !pid) return;

    if (!user || !user.data || !user.data.id) {
      const message = "Failed to get data: no user provided.";

      dispatch(
        enqueueSnackbar({
          message,
          options: {
            variant: "error",
          },
        })
      );

      dispatch(getOrderFailure(message));
      return;
    } else if (getProviderPermission(user.data) === 403) {
      const message = "Failed to get data: permission denied.";

      dispatch(
        enqueueSnackbar({
          message,
          options: {
            variant: "error",
          },
        })
      );

      dispatch(getOrderFailure(message));
      return;
    }

    dispatch(getOrder());

    fetchApi(
      "get",
      `providers/all/order-products`,
      { filters: `id|eq|${pid},order_id|eq|${oid}` },
      null,
      null,
      (data) => {
        if (data && data[0]) {
          dispatch(getOrderSuccess(data[0]));
        } else {
          const message = "Failed to get data: params not valid.";

          dispatch(
            enqueueSnackbar({
              message,
              options: {
                variant: "error",
              },
            })
          );

          dispatch(getOrderFailure(message));
        }
      },
      (error) => {
        const errorMessage = translation().orders.callbacks.get_order_failed;

        if (error.response)
          dispatch(
            enqueueSnackbar({
              message:
                error.response.data && error.response.data.detail
                  ? error.response.data.detail
                  : errorMessage,
              options: {
                variant: "error",
              },
            })
          );
        else
          dispatch(
            enqueueSnackbar({
              message: errorMessage,
              options: {
                variant: "error",
              },
            })
          );

        dispatch(getOrderFailure(error));
      }
    );
  };

  return {
    getOrder: (user, id, pid) => {
      getProviderOrder(user, id, pid);
    },
    clearMediaGroups: () => {
      dispatch(clearMediaGroups());
    },
    getProductMediaGroups: (oid, pid) => {
      if (!oid || !pid) return;

      dispatch(getProductMediaGroups());

      fetchApi(
        "get",
        `orders/${oid}/products/${pid}/medias/structured`,
        null,
        null,
        null,
        (data) => {
          dispatch(getProductMediaGroupsSuccess(data));
        },
        (error) => {
          dispatch(getProductMediaGroupsFailure(error));
        }
      );
    },
    uploadOrder(opMedias, oid, pid, onSuccess, onFailure) {
      if (!opMedias || !pid) return;

      let mediasOrderArray = [];

      /**
       * Loop on all orderProductsMedias mediasTypes
       * Then get the medias for all mediasTypes and push it to the array for send to api
       */
      if (Object.keys(opMedias).length) {
        for (let mediaType in opMedias) {
          if (opMedias[mediaType].medias && opMedias[mediaType].medias.length) {
            for (let j = 0; j < opMedias[mediaType].medias.length; j++) {
              let mediasToPost = {
                url: opMedias[mediaType].medias[j].url
                  ? opMedias[mediaType].medias[j].url
                  : null,
                media_type_id: opMedias[mediaType].id
                  ? opMedias[mediaType].id
                  : null,
                floor: opMedias[mediaType].medias[j].floor
                  ? opMedias[mediaType].medias[j].floor
                  : null,
                position: opMedias[mediaType].medias[j].position
                  ? opMedias[mediaType].medias[j].position
                  : null,
              };

              /**
               * Check if media have media template id key and if his value exists, then pass value to it
               */
              let media_template_id = null;

              if (
                opMedias[mediaType].medias[j].media_type &&
                opMedias[mediaType].medias[j].media_type.has_templates
              ) {
                if (opMedias[mediaType].medias[j]["media_template_id"]) {
                  media_template_id =
                    opMedias[mediaType].medias[j].media_template_id;
                } else if (
                  opMedias[mediaType].medias[j]["media_template"] &&
                  opMedias[mediaType].medias[j].media_template["id"]
                ) {
                  media_template_id =
                    opMedias[mediaType].medias[j].media_template.id;
                } else if (
                  opMedias[mediaType].medias[j]["media_template"] === null
                ) {
                  media_template_id = "null";
                }

                if (media_template_id)
                  mediasToPost.media_template_id = media_template_id;
              }

              mediasOrderArray.push(mediasToPost);
            }
          }
        }
      }

      /**
       * Check if all media templates fields are filled
       */
      if (!checkBeforeSubmit(mediasOrderArray)) {
        const message = translation().orders.callbacks.fill_templates;

        dispatch(
          enqueueSnackbar({
            message,
            options: {
              variant: "warning",
            },
          })
        );

        return;
      }

      const mediasOrder = {
        medias: mediasOrderArray,
      };

      dispatch(postOrderProductIdMedias());

      fetchApi(
        "post",
        `orders/${oid}/products/${pid}/medias/upload`,
        null,
        mediasOrder,
        null,
        () => {
          const message = translation().orders.callbacks.medias_send_success;

          dispatch(
            enqueueSnackbar({
              message,
              options: {
                variant: "success",
              },
            })
          );

          dispatch(postOrderProductIdMediasSuccess());

          if (onSuccess && typeof onSuccess === "function") onSuccess();
        },
        (error) => {
          const errorMessage = translation().orders.callbacks
            .medias_send_failure;

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : errorMessage,
                options: {
                  variant: "error",
                },
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: errorMessage,
                options: {
                  variant: "error",
                },
              })
            );
          }

          if (onFailure && typeof onFailure === "function") onFailure();

          dispatch(postOrderProductIdMediasFailure(error));
        }
      );
    },
    downloadOrderProductMedias(
      oid,
      pid,
      query = {},
      onSuccess = null,
      onFailure = null
    ) {
      if (!oid || !pid || !query || !query.media_group_id) return;

      fetchApi(
        "post",
        `orders/${oid}/products/${pid}/medias/download`,
        null,
        query,
        null,
        (url) => {
          const downloadIframe = document.getElementById("frame-download");
          const iframeContainer = document.getElementById("iframes-container");

          if (downloadIframe) {
            downloadIframe.setAttribute("src", url);
          } else {
            const downloadIframe =
              '<iframe id="frame-download" title="iframe-download" width="0" height="0" style="display: none" src=' +
              url +
              " />";
            iframeContainer.innerHTML = downloadIframe;
          }

          if (onSuccess && typeof onSuccess === "function") {
            onSuccess();
          }
        },
        (error) => {
          const errorMessage = "Failed to download medias";

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : errorMessage,
                options: {
                  variant: "error",
                },
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: errorMessage,
                options: {
                  variant: "error",
                },
              })
            );
          }

          if (onFailure && typeof onFailure === "function") {
            onFailure();
          }
        }
      );
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Order));
