import { getApiConfigFromLocation } from "./helpers/functions/utils";

export default {
  site_version: "v1.0.8",
  site_name: "LightShop Finisher",
  site_brand: "Wall-Market",
  phone_number: "+33 1 73 79 13 84",
  contact_email: "providers@wall-market.com",
  primary_color: "#FFD700",
  secondary_color: "#F29D06",
  dark_color: "#1A2123",

  providerProductsMatches: {
    14698: [26, 27, 28], // HSV
    14697: [36, 41], // Forms
    14696: [5, 6, 7, 8, 9, 10, 15, 17, 23, 24, 25, 29, 30, 32, 33, 34, 37, 40], // Plans
    9248: [15, 17, 25], // Snaps
  },

  env_mode: process.env.NODE_ENV === "production" ? "prod" : "test",
  // env_mode: "PROD",
  // env_mode: "test",

  api_url: getApiConfigFromLocation("api_url"),
  api_key: getApiConfigFromLocation("api_key"),
  api_env: getApiConfigFromLocation("name"),

  /**
   * COOKIES
   */
  access_token_cookie_name: "_Secure-access_token",
  locale_cookie_name: "locale",

  /**
   * DEFAULT VALUES
   */
  default_locale: "fr",
  default_currency: "EUR",
  default_currency_symbol: "€",
  default_timezone: "Europe/Paris",
  default_country: "FR",
  default_unit_system: "metric",
  open_countries_lang: [
    { code: "de", name: "Deutsch" },
    { code: "en", name: "English" },
    { code: "es", name: "Español" },
    { code: "fr", name: "Français" },
    { code: "it", name: "Italiano" },
    { code: "no", name: "Norsk" },
  ],
  open_countries_currencies: [
    { code: "CAD", name: "Canadian Dollar" },
    { code: "CHF", name: "Franc suisse" },
    { code: "EUR", name: "Euro" },
    { code: "GBP", name: "British Pound" },
    { code: "NOK", name: "Norsk Krone" },
    { code: "SEK", name: "Svensk Krona" },
    { code: "USD", name: "US Dollar" },
  ],
};
