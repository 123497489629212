/* eslint-disable react/jsx-no-bind */
import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import { Button, CircularProgress, Fab, Typography } from "@material-ui/core";
import { Add, Delete } from "@material-ui/icons";

import translation from "../../../translation/translation";
import DropCard from "./DropCard";

function Dropper(props) {
  const {
    accept,
    disabled,
    filesArray,
    maxSize,
    minSize,
    onClearDroppper,
    onDeleteFile,
    onDrop,
    showFilesName,
    uploadInProgressCount,
  } = props;

  /**
   * Copy dropzone click for isolate it to the button
   * Then disable the dropzone click for more usability of sortable component
   */
  let dropzoneRef = undefined;

  function handleDrop(filesAccepted, filesRejected) {
    if (onDrop) {
      onDrop(filesAccepted, filesRejected);
    }
  }
  function handleOpenDropzone() {
    if (dropzoneRef && dropzoneRef.open) dropzoneRef.open();
  }

  function createDropzoneRef(node) {
    if (node) dropzoneRef = node;
  }

  return (
    <Dropzone
      ref={createDropzoneRef}
      onDrop={handleDrop}
      disableClick={true}
      multiple={true}
      accept={accept}
      maxSize={maxSize}
      minSize={minSize}
    >
      {({ getRootProps, getInputProps, isDragActive }) => {
        return (
          <div className="drop-zone">
            <div
              {...getRootProps({
                className: classNames("dropzone dropper", {
                  "dropzone--isActive dropper-ondrag": isDragActive,
                }),
              })}
            >
              <input {...getInputProps()} />
              {!uploadInProgressCount &&
              filesArray &&
              filesArray.length <= 0 ? (
                <div className="drop-it">
                  <i className="material-icons drop-icon">cloud_upload</i>
                  <Typography>
                    {translation().orders.media_groups.file_uploader.drop_file}
                  </Typography>
                  <Typography className="or-click" component="span">
                    {translation().orders.media_groups.file_uploader.or}
                  </Typography>
                  <Button
                    className="drop-btn"
                    onClick={handleOpenDropzone}
                    aria-label="Drop files"
                    color="default"
                  >
                    {translation().orders.media_groups.file_uploader.select}
                  </Button>
                </div>
              ) : (
                <>
                  <Fab
                    aria-label="Remove all files"
                    className="drop-corner-btn remove-all"
                    disabled={disabled}
                    onClick={onClearDroppper}
                    size="small"
                  >
                    <Delete />
                  </Fab>
                  <Fab
                    aria-label="Drop files"
                    className="drop-corner-btn"
                    color="primary"
                    disabled={disabled}
                    onClick={handleOpenDropzone}
                    size="small"
                  >
                    <Add />
                  </Fab>
                </>
              )}
              {filesArray && filesArray.length > 0
                ? filesArray.map((file, index) => {
                    return (
                      <DropCard
                        key={index}
                        disabled={disabled}
                        file={file}
                        onDeleteFile={onDeleteFile}
                        showFilesName={showFilesName}
                      />
                    );
                  })
                : ""}
              {_.times(uploadInProgressCount, (index) => (
                <div className="MuiPaper-root file-incoming" key={index}>
                  <div className="file-loader">
                    <CircularProgress color="secondary" size={35} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      }}
    </Dropzone>
  );
}

Dropper.defaultProps = {
  accept: "",
  disabled: false,
  maxSize: 10000000,
  minSize: 0,
  showFilesName: false,
};

Dropper.propTypes = {
  accept: PropTypes.string,
  disabled: PropTypes.bool,
  filesArray: PropTypes.arrayOf(PropTypes.shape()),
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  onClearDroppper: PropTypes.func.isRequired,
  onDeleteFile: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  showFilesName: PropTypes.bool,
  uploadInProgressCount: PropTypes.number,
};

export default Dropper;
