import translation from '../../../../translation/translation';

const validate = values => {

  const errors = {};

  if (!values.unit_system)
    errors.unit_system = translation().forms.errors.required;

  if (!values.language_locale)
    errors.language_locale = translation().forms.errors.required;

  if (!values.currency_isocode)
    errors.currency_isocode = translation().forms.errors.required;

  if (!values.citizen_country_isocode)
    errors.citizen_country_isocode = translation().forms.errors.required;

  if (!values.residence_country_isocode)
    errors.residence_country_isocode = translation().forms.errors.required;

  return errors;
}

export default validate;
