import store from '../../redux/store';

import {
  storeMediaType,
  removeMediaTypes,
  storeMediaTypeMedia,
  removeMediaTypeMedia,
  removeMediaTypeMedias,
  storeOpmInitBuffer,
  storeOpmProductId,
  storeMediaTypeProperty,
  removeOpmInitBuffer
} from '../../redux/actions/orderProductMediasActions';

/**
 * Set a mediaType in redux
 */
export const setMediaType = mediaType => {
  if (mediaType)
    store.dispatch(storeMediaType(mediaType));
}

/**
 * Remove into redux all mediaTypes
 */
export const unsetMediaTypes = () => {
  store.dispatch(removeMediaTypes());
}

/**
 * Add a media in a mediaType in redux
 */
export const setMediaTypeMedia = (media, mediaTypeShortcode) => {
  if (media && mediaTypeShortcode)
    store.dispatch(storeMediaTypeMedia(media, mediaTypeShortcode));
}

/**
 * Get all medias for a mediaType from redux for a given shortcode
 */
export const getMediaTypeMedias = mediaTypeShortcode => {
  const state = store.getState();
  const medias = state.orderProductMedias[mediaTypeShortcode].medias;
  return medias;
}

/**
 * Get all mediaTypes from redux
 */
export const getOrderProductMedias = () => {
  const state = store.getState();
  const medias = state.orderProductMedias
  return medias;
}

/**
 * Remove a media from a mediaType in redux
 */
export const unsetMediaTypeMedia = (mediaUrl, mediaTypeShortcode) => {
  if (mediaUrl && mediaTypeShortcode)
    store.dispatch(removeMediaTypeMedia(mediaUrl, mediaTypeShortcode));
}

/**
 * Set a property media type to a mediaType in redux
 */
export const setMediaTypeProperty = (mediaUrl, mediaTypeShortcode, mediaProperty, mediaPropertyValue) => {
  if (mediaUrl && mediaTypeShortcode && mediaProperty && mediaPropertyValue)
    store.dispatch(storeMediaTypeProperty(mediaUrl, mediaTypeShortcode, mediaProperty, mediaPropertyValue));
}

/**
 * Reset all medias in a mediaType in redux
 */
export const resetMediaTypeMedias = mediaTypeShortcode => {
  if (mediaTypeShortcode)
    store.dispatch(removeMediaTypeMedias(mediaTypeShortcode));
}

/**
 * Set the orderProductMedias status
 */
export const setOpmInitBuffer = status => {
  if (status)
    store.dispatch(storeOpmInitBuffer(status));
}

/**
 * Set the orderProductMedias product id
 */
export const setOpmProductId = id => {
  if (id)
    store.dispatch(storeOpmProductId(id));
}

/**
 * Reset the orderProductMedias status bucket
 */
export const resetOpmInitBuffer = () => {
  store.dispatch(removeOpmInitBuffer());
}

/**
 * Get the orderProductMedias product id
 */
export const getOpmProductId = () => {
  const state = store.getState();
  const orderProductMediasStatus = state.orderProductMediasStatus;
  return orderProductMediasStatus;
}
