/**
 * Custom ex template : https://github.com/mui-org/material-ui/issues/13546
 */

import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';

const MuiTextField = ({
  input,
  fullWidth,
  disabled,
  placeHolder,
  label,
  type,
  className,
  InputLabelProps,
  id,
  meta: { touched, error }
}) => {
  return (
    <div>
      <TextField
        {...input}
        id={id}
        fullWidth={fullWidth}
        type={type}
        label={label}
        placeholder={placeHolder}
        InputLabelProps={InputLabelProps}
        disabled={disabled}
        className={className}
        error={touched && error ? true : false}
      />
      { touched && error && <FormHelperText error>{error}</FormHelperText> }
    </div>
  );
}

MuiTextField.defaultProps = {
  fullWidth: true
}

MuiTextField.propTypes = {
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  placeHolder: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.any,
  input: PropTypes.shape(),
  InputLabelProps: PropTypes.shape(),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.any
  })
}

export default MuiTextField;
