import React, { Component } from "react";
import PropTypes from "prop-types";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Chip from "@material-ui/core/Chip";
import CloseIcon from "@material-ui/icons/Close";
import CommentIcon from "@material-ui/icons/Comment";
import Fab from "@material-ui/core/Fab";
import PersonIcon from "@material-ui/icons/Person";
import RoomIcon from "@material-ui/icons/Room";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import TodayIcon from "@material-ui/icons/Today";
import WorkIcon from "@material-ui/icons/Work";

import {
  adjustOrderCard,
  orderContentScroll,
} from "../../../helpers/functions/jquery";
import {
  dateInTz,
  convertMinsToHrsMins,
} from "../../../helpers/functions/dates";
import { addThreeDots } from "../../../helpers/functions/utils";
import MediaGroups from "../../../components/commons/orders/mediaGroups/MediaGroups";
import translation from "../../../translation/translation";

class OrderCard extends Component {
  constructor(props) {
    super(props);

    this.handleEscKeyPress = this.handleEscKeyPress.bind(this);
    this.handleOpenOrderContent = this.handleOpenOrderContent.bind(this);
    this.handleCloseOrderContent = this.handleCloseOrderContent.bind(this);
    this.handleUploadOrder = this.handleUploadOrder.bind(this);
  }

  componentWillMount() {
    window.addEventListener("resize", adjustOrderCard);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", adjustOrderCard);
    window.removeEventListener("keydown", this.handleEscKeyPress);
  }

  handleEscKeyPress(event) {
    event = event || window.event;
    let isEscape = false;

    if (event.key) isEscape = event.key === "Escape" || event.key === "Esc";
    else if (event.code) isEscape = event.code === "Escape";
    else isEscape = event.keyCode === 27;

    if (isEscape) this.handleCloseOrderContent();
  }

  handleOpenOrderContent() {
    const {
      data: { id },
      orderOpen,
      onOpenOrder,
    } = this.props;

    window.addEventListener("keydown", this.handleEscKeyPress);

    const isOrderOpen = orderOpen === id ? true : false;

    if (isOrderOpen) {
      this.handleCloseOrderContent();
      return;
    }

    onOpenOrder(id);
    orderContentScroll(id);
  }

  handleCloseOrderContent() {
    window.removeEventListener("keydown", this.handleEscKeyPress);

    this.setState({
      order: null,
      isOrderLoading: false,
    });

    const { onCloseOrder } = this.props;

    onCloseOrder();
  }

  renderOptions(options) {
    if (!options) return;

    let optionsChips = [];

    if (options.length > 0) {
      for (let i = 0; i < options.length; i++) {
        optionsChips.push(
          <Chip key={i} className="option" label={options[i].name} />
        );
      }
    }

    return optionsChips;
  }

  handleUploadOrder() {
    const {
      data,
      getOrders,
      onCloseOrder,
      orderProductMedias,
      uploadAction,
      uploadOrder,
      user,
    } = this.props;

    if (uploadAction.isLoading) return;

    if (orderProductMedias && data.id && data.order_id) {
      uploadOrder(orderProductMedias, data.order_id, data.id, () => {
        onCloseOrder();
        getOrders(null, user);
      });
    }
  }

  checkIsOrderReadyToSend() {
    const { orderProductMedias } = this.props;

    let mediaTypesReady = 0;

    if (orderProductMedias && Object.keys(orderProductMedias).length) {
      for (let mediaType in orderProductMedias) {
        const nbMedias = orderProductMedias[mediaType].medias
          ? orderProductMedias[mediaType].medias.length
          : 0;
        const nbMin = orderProductMedias[mediaType].quantity_min
          ? orderProductMedias[mediaType].quantity_min
          : 0;
        const nbMax = orderProductMedias[mediaType].quantity_max
          ? orderProductMedias[mediaType].quantity_max
          : 10000000;

        if (nbMedias >= nbMin && nbMedias <= nbMax) mediaTypesReady++;
      }

      if (mediaTypesReady === Object.keys(orderProductMedias).length)
        return true;
      else return false;
    } else return false;
  }

  render() {
    const {
      clearMediaGroups,
      downloadOrderProductMedias,
      data,
      isLoading,
      getProductMediaGroups,
      mediaGroups,
      uploadAction,
      orderOpen,
    } = this.props;

    const isOrderOpen = orderOpen === data.id ? true : false;

    let backgroundImage = "/assets/images/account/order-planned.jpg";

    if (
      data.delivery_status &&
      data.delivery_status.shortcode &&
      data.delivery_status.shortcode === "done"
    ) {
      backgroundImage = "/assets/images/account/order-done.jpg";
    }

    if (data.cover_url) backgroundImage = data.cover_url;

    const coverStyle = {
      backgroundImage: "url(" + backgroundImage + ")",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    };

    return (
      <li
        className={"Order " + (isOrderOpen ? "open" : "")}
        id={"order-" + data.id}
      >
        <div className="shadow-wrapper">
          <div className="order-cover" style={coverStyle}>
            <Fab
              disabled={
                isLoading || mediaGroups.isLoading || uploadAction.isLoading
              }
              className="mui-trigger-content"
              onClick={this.handleOpenOrderContent}
            >
              <div className={"trigger-content " + (isOrderOpen ? "open" : "")}>
                <span className="icon-bar" />
                <span className="icon-bar" />
                <span className="icon-bar" />
              </div>
            </Fab>
          </div>
          <div className="overview">
            <div className="col-sm-6">
              {data.order_id && (
                <p className="order-adres" style={{ fontWeight: 500 }}>
                  <span className="detail">Order: #{data.order_id}</span>
                </p>
              )}
            </div>
            <div className="col-sm-6">
              <p
                className="order-adres"
                style={{ textAlign: "right", fontWeight: 500 }}
              >
                {data.order &&
                  data.order.property &&
                  data.order.property.size_formatted && (
                    <span className="detail">
                      {data.order.property.size_formatted}
                    </span>
                  )}
              </p>
            </div>
            <div className="clearfix" />
            <div className="timeline">
              {data.order &&
                data.order.company &&
                data.order.company.commercial_name && (
                  <div
                    className="col-xs-12 planning"
                    style={{ marginBottom: 8 }}
                  >
                    <span>
                      <span className="planning-icon icon-connecte" />{" "}
                      {data.order.company.commercial_name}
                    </span>
                  </div>
                )}
              <div className="clearfix" />
              <div className="col-xs-5 planning">
                {data.start ? (
                  <span>
                    <span className="planning-icon icon-calendrier" />{" "}
                    {dateInTz(data.start, "localized-date")}
                  </span>
                ) : (
                  <br />
                )}
              </div>
              <div className="col-xs-3 planning">
                {data.start ? (
                  <span>
                    <span className="planning-icon icon-horaire" />{" "}
                    {dateInTz(data.start, "HH:mm")}
                  </span>
                ) : (
                  <br />
                )}
              </div>
              <div
                className="col-xs-4 status"
                style={{
                  color: data.delivery_status.color
                    ? data.delivery_status.color
                    : "#000000",
                }}
              >
                <img
                  alt="Status"
                  src={
                    data.delivery_status && data.delivery_status.icon_url_dark
                      ? data.delivery_status.icon_url_dark
                      : ""
                  }
                  className="status-img"
                />
                {data.delivery_status && data.delivery_status.name
                  ? data.delivery_status.name
                  : ""}
              </div>
              <div className="clearfix" />
            </div>
            <div className="time-track">
              <div className="tracker" />
            </div>
            <div className="products-img">
              <div
                className={
                  "product-img " + (data.uploadable ? "" : "unavailable")
                }
              >
                <img
                  src={data.icon_url_dark ? data.icon_url_dark : ""}
                  alt={data.name ? data.name : "Missing img"}
                />
              </div>
              <span
                className={"name " + (data.uploadable ? "" : "unavailable")}
              >
                {data.name ? addThreeDots(data.name, 35) : ""}
              </span>
            </div>
          </div>
        </div>
        <div className="order-pointer">
          <div className="pointer" />
        </div>
        {isOrderOpen && (
          <div className="order-wrapper" ref={adjustOrderCard}>
            <div className="order-actions">
              <IconButton
                aria-label="Close"
                className="order-action"
                onClick={this.handleCloseOrderContent}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div className="container">
              <div className="order-content row">
                <div className="col-xs-12 col-sm-9">
                  {data.name && <h2>{data.name}</h2>}
                  <div className="details row">
                    <div className="col-sm-12 col-md-6">
                      <div className="planning">
                        <h4>{translation().orders.order}</h4>
                        {data.id && (
                          <div className="detail-row">
                            {data.order_id && (
                              <span className="detail">
                                <span className="detail-icon">
                                  <ShoppingCartIcon fontSize="small" />
                                </span>{" "}
                                #{data.order_id}
                              </span>
                            )}
                          </div>
                        )}
                        {data.start && (
                          <div className="detail-row">
                            <span className="detail">
                              <span
                                className="detail-icon"
                                title={translation().orders.date}
                              >
                                <TodayIcon fontSize="small" />
                              </span>
                              {dateInTz(data.start, "localized-date")}
                            </span>
                            <span className="detail">
                              <span
                                className="detail-icon"
                                title={translation().orders.time}
                              >
                                <AccessTimeIcon fontSize="small" />
                              </span>
                              {dateInTz(data.start, "HH:mm")}
                            </span>
                          </div>
                        )}
                        <div className="detail-row">
                          {data.duration && (
                            <span className="detail">
                              <span
                                className="detail-icon"
                                title={translation().orders.duration}
                              >
                                <TimelapseIcon fontSize="small" />
                              </span>
                              {convertMinsToHrsMins(data.duration, "h")}
                            </span>
                          )}
                          {data.order &&
                            data.order.property &&
                            data.order.property.size_formatted && (
                              <span className="detail">
                                <span
                                  className="detail-icon icon-surface"
                                  title={translation().orders.size}
                                />
                                {data.order.property.size_formatted}
                              </span>
                            )}
                        </div>
                        {data.provider_name && (
                          <div className="detail-row">
                            <span className="detail">
                              <span
                                className="detail-icon"
                                title={translation().orders.provider_name}
                              >
                                <WorkIcon fontSize="small" />
                              </span>{" "}
                              <span>{data.provider_name}</span>
                            </span>
                          </div>
                        )}
                      </div>
                      {data.options && data.options.length > 0 ? (
                        <div className="options">
                          <h4>{translation().orders.options}</h4>
                          {this.renderOptions(data.options)}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <h4>{translation().orders.details}</h4>
                      {data.order && (
                        <div>
                          {data.order.company &&
                            data.order.company.commercial_name && (
                              <div className="detail-row">
                                <span className="detail">
                                  <span className="detail-icon">
                                    <PersonIcon fontSize="small" />
                                  </span>
                                  {data.order.company.commercial_name}
                                </span>
                              </div>
                            )}
                          {data.order.property && data.order.property.address && (
                            <div className="detail-row">
                              <span className="detail">
                                <span className="detail-icon">
                                  <RoomIcon fontSize="small" />
                                </span>{" "}
                                {data.order.property.address}
                              </span>
                            </div>
                          )}
                          {data && data.property_contact_comment && (
                            <div className="detail-row">
                              <span
                                className="detail"
                                style={{ marginTop: 12 }}
                              >
                                <div className="detail-icon">
                                  <CommentIcon fontSize="small" />
                                </div>
                                <p className="detail-comments">
                                  {data.property_contact_comment}
                                </p>
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="clearfix" />
                  </div>
                </div>
                <div className="col-xs-12 col-sm-3 margin-mobile col-btn-dl">
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={
                      !this.checkIsOrderReadyToSend() || uploadAction.isLoading
                    }
                    onClick={this.handleUploadOrder}
                  >
                    <i className="material-icons" style={{ marginRight: 5 }}>
                      unarchive
                    </i>
                    <span>{translation().orders.send}</span>
                  </Button>
                </div>
                <div className="clearfix" />
                <MediaGroups
                  clearMediaGroups={clearMediaGroups}
                  downloadOrderProductMedias={downloadOrderProductMedias}
                  getProductMediaGroups={getProductMediaGroups}
                  mediaGroups={mediaGroups}
                  product={data}
                  mode="providers"
                />
                <div className="col-xs-12 col-btn-dl">
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={
                      !this.checkIsOrderReadyToSend() || uploadAction.isLoading
                    }
                    onClick={this.handleUploadOrder}
                  >
                    <i className="material-icons" style={{ marginRight: 5 }}>
                      unarchive
                    </i>
                    <span>{translation().orders.send}</span>
                  </Button>
                </div>
                <div className="clearfix" />
              </div>
            </div>
          </div>
        )}
      </li>
    );
  }
}

OrderCard.propTypes = {
  clearMediaGroups: PropTypes.func.isRequired,
  data: PropTypes.shape(),
  downloadOrderProductMedias: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  getOrders: PropTypes.func.isRequired,
  getProductMediaGroups: PropTypes.func.isRequired,
  mediaGroups: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape()),
    isLoading: PropTypes.bool,
  }),
  orderOpen: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onOpenOrder: PropTypes.func.isRequired,
  onCloseOrder: PropTypes.func.isRequired,
  orderProductMedias: PropTypes.shape(),
  uploadAction: PropTypes.shape({
    isLoading: PropTypes.bool,
  }).isRequired,
  uploadOrder: PropTypes.func.isRequired,
  user: PropTypes.shape().isRequired,
};

export default OrderCard;
