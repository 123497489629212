/* eslint-disable react/jsx-no-bind */
import "react-phone-number-input/style.css";
import "./muiPhoneField.css";

import React from "react";
import PropTypes from "prop-types";
import PhoneInput from "react-phone-number-input";

import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";

import { getUserFromStore } from "../../../../helpers/functions/user";
import config from "../../../../config";

function InternationalIcon() {
  const iconStyle = {
    fontSize: 18,
    position: "relative",
    bottom: 2,
    left: 4,
    color: "rgba(0, 0, 0, 0.52)",
  };

  return (
    <i className="material-icons" style={iconStyle}>
      language
    </i>
  );
}

function getUserResidenceCountry() {
  const user = getUserFromStore();

  if (user && user.residence_country && user.residence_country.isocode)
    return user.residence_country.isocode.toLowerCase();
  else return config.default_country;
}

function MuiPhoneField(props) {
  const {
    autoComplete,
    country,
    disabled,
    displayInitialValueAsLocalNumber,
    id,
    fullWidth,
    onChange,
    label,
    required,
    write,
    input,
    meta: { touched, error },
  } = props;

  function handleChange(value) {
    if (input && input.onChange) input.onChange(value || "");

    if (onChange) onChange(value || "");
  }

  function handleBlur() {
    if (input && input.onBlur) input.onBlur();
  }

  const userCountry = getUserResidenceCountry()
    ? getUserResidenceCountry().toUpperCase()
    : undefined;
  const finalClassName = "MuiPhoneField " + (error ? "error " : " ");

  return (
    <div className={finalClassName}>
      <FormControl
        required={required}
        disabled={!write || disabled}
        fullWidth={fullWidth}
        error={error ? true : false}
      >
        <InputLabel error={error ? true : false} htmlFor={id} shrink>
          {label}
        </InputLabel>
        <div className="PhoneInput-InputBase">
          <PhoneInput
            id={id || input.name}
            name={input.name}
            disabled={!write || disabled}
            displayInitialValueAsLocalNumber={displayInitialValueAsLocalNumber}
            required={required}
            autoComplete={autoComplete}
            country={country || userCountry}
            onChange={handleChange}
            placeholder={label}
            value={input.value}
            onBlur={handleBlur}
            internationalIcon={InternationalIcon}
          />
        </div>
      </FormControl>
      {touched && error && <FormHelperText error>{error}</FormHelperText>}
    </div>
  );
}

MuiPhoneField.defaultProps = {
  autoComplete: "nope",
  country: undefined,
  displayInitialValueAsLocalNumber: true,
  fullWidth: true,
  multiline: false,
  type: "text",
  rows: null,
  write: true,
};

MuiPhoneField.propTypes = {
  autoComplete: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  country: PropTypes.string,
  //eslint-disable-next-line
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  displayInitialValueAsLocalNumber: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  input: PropTypes.shape(),
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.any,
  }),
  required: PropTypes.bool,
  onChange: PropTypes.func,
  write: PropTypes.bool,
};

export default MuiPhoneField;
