import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, IconButton, Chip, CircularProgress } from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CommentIcon from "@material-ui/icons/Comment";
import PersonIcon from "@material-ui/icons/Person";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import TodayIcon from "@material-ui/icons/Today";
import RoomIcon from "@material-ui/icons/Room";
import WorkIcon from "@material-ui/icons/Work";

import { dateInTz, convertMinsToHrsMins } from "../../helpers/functions/dates";
import { addThreeDots } from "../../helpers/functions/utils";
import MediaGroups from "../../components/commons/orders/mediaGroups/MediaGroups";
import translation from "../../translation/translation";
import config from "../../config";

function DownloadIconButton({ link }) {
  function handleDownloadMedia() {
    const url = link + "/download?client_id=" + config.api_key;
    const downloadIframe = document.getElementById("frame-download");
    const iframeContainer = document.getElementById("iframes-container");

    if (downloadIframe) {
      downloadIframe.setAttribute("src", url);
    } else {
      const downloadIframe =
        '<iframe id="frame-download" title="iframe-download" width="0" height="0" style="display: none" src=' +
        url +
        " />";
      iframeContainer.innerHTML = downloadIframe;
    }
  }

  return (
    <IconButton
      aria-label="download"
      style={{ padding: 7, color: "#FFFFFF" }}
      onClick={handleDownloadMedia}
      size="small"
    >
      <span className="icon-download" style={{ fontSize: 12 }} />
    </IconButton>
  );
}

DownloadIconButton.propTypes = {
  link: PropTypes.string.isRequired,
};

class Order extends Component {
  constructor(props) {
    super(props);

    this.handleUploadOrder = this.handleUploadOrder.bind(this);
    this.renderOrder = this.renderOrder.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { getOrder, match, user } = this.props;

    if (match && match.params) {
      const { oid, pid } = match.params;

      getOrder(user, oid, pid);
    }
  }

  renderOptions(options) {
    if (!options) return;

    let optionsChips = [];

    if (options.length > 0) {
      for (let i = 0; i < options.length; i++) {
        optionsChips.push(
          <Chip
            key={i}
            className="option"
            label={"Option : " + options[i].name}
          />
        );
      }
    }

    return optionsChips;
  }

  renderAttributes(attr) {
    switch (attr.type) {
      case "file":
        return <DownloadIconButton link={attr.value} />;
      case "image":
        return <DownloadIconButton link={attr.value} />;
      default:
        return attr.value;
    }
  }

  handleUploadOrder() {
    const {
      getOrder,
      uploadOrder,
      orderProductMedias,
      order: { data, upload_action },
      user,
    } = this.props;

    if (upload_action.isLoading) return;

    if (orderProductMedias && data.order_id && data.id) {
      uploadOrder(orderProductMedias, data.order_id, data.id, () => {
        getOrder(user, data.order_id, data.id);
      });
    }
  }

  renderOrder(data) {
    if (!data) return;

    const {
      clearMediaGroups,
      downloadOrderProductMedias,
      getProductMediaGroups,
      orderProductMedias,
      order: { mediaGroups, upload_action },
    } = this.props;

    const checkIsOrderReadyToSend = () => {
      let mediaTypesReady = 0;

      if (orderProductMedias && Object.keys(orderProductMedias).length) {
        for (let mediaType in orderProductMedias) {
          const nbMedias = orderProductMedias[mediaType].medias
            ? orderProductMedias[mediaType].medias.length
            : 0;
          const nbMin = orderProductMedias[mediaType].quantity_min
            ? orderProductMedias[mediaType].quantity_min
            : 0;
          const nbMax = orderProductMedias[mediaType].quantity_max
            ? orderProductMedias[mediaType].quantity_max
            : 10000000;

          if (nbMedias >= nbMin && nbMedias <= nbMax) mediaTypesReady++;
        }

        if (mediaTypesReady === Object.keys(orderProductMedias).length)
          return true;
        else return false;
      } else return false;
    };

    let backgroundImage = "/assets/images/account/order-planned.jpg";

    if (
      data.delivery_status &&
      data.delivery_status.shortcode &&
      data.delivery_status.shortcode === "done"
    ) {
      backgroundImage = "/assets/images/account/order-done.jpg";
    }

    if (data.cover_url) backgroundImage = data.cover_url;

    const coverStyle = {
      backgroundImage: "url(" + backgroundImage + ")",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    };

    return (
      <div className="Order open">
        {upload_action.isLoading && (
          <div className="upload-order-overlay">
            <div className="center-loader">
              <CircularProgress color="primary" size={65} />
            </div>
          </div>
        )}
        <div className="order-wrapper-no-resize">
          <div className="container">
            <div className="order-content">
              <div className="col-xs-12 col-sm-5 col-md-4 col-lg-4">
                <div className="shadow-wrapper">
                  <div className="order-cover" style={coverStyle} />
                  <div className="overview">
                    <div className="col-sm-6">
                      {data.order_id && (
                        <p className="order-adres" style={{ fontWeight: 500 }}>
                          <span className="detail">
                            Order: #{data.order_id}
                          </span>
                        </p>
                      )}
                    </div>
                    <div className="col-sm-6">
                      <p
                        className="order-adres"
                        style={{ textAlign: "right", fontWeight: 500 }}
                      >
                        {data.order &&
                          data.order.property &&
                          data.order.property.size_formatted && (
                            <span className="detail">
                              {data.order.property.size_formatted}
                            </span>
                          )}
                      </p>
                    </div>
                    <div className="clearfix" />
                    <div className="timeline">
                      {data.order &&
                        data.order.company &&
                        data.order.company.commercial_name && (
                          <div
                            className="col-xs-12 planning"
                            style={{ marginBottom: 8 }}
                          >
                            <span>
                              <span className="planning-icon icon-connecte" />{" "}
                              {data.order.company.commercial_name}
                            </span>
                          </div>
                        )}
                      <div className="clearfix" />
                      <div className="col-xs-5 planning">
                        {data.start ? (
                          <span>
                            <span className="planning-icon icon-calendrier" />{" "}
                            {dateInTz(data.start, "localized-date")}
                          </span>
                        ) : (
                          <br />
                        )}
                      </div>
                      <div className="col-xs-3 planning">
                        {data.start ? (
                          <span>
                            <span className="planning-icon icon-horaire" />{" "}
                            {dateInTz(data.start, "HH:mm")}
                          </span>
                        ) : (
                          <br />
                        )}
                      </div>
                      <div
                        className="col-xs-4 status"
                        style={{
                          color: data.delivery_status.color
                            ? data.delivery_status.color
                            : "#000000",
                        }}
                      >
                        <img
                          alt="Status"
                          src={
                            data.delivery_status &&
                            data.delivery_status.icon_url_dark
                              ? data.delivery_status.icon_url_dark
                              : ""
                          }
                          className="status-img"
                        />
                        {data.delivery_status && data.delivery_status.name
                          ? data.delivery_status.name
                          : ""}
                      </div>
                      <div className="clearfix" />
                    </div>
                    <div className="time-track">
                      <div className="tracker" />
                    </div>
                    <div className="products-img">
                      <div
                        className={
                          "product-img " +
                          (data.uploadable ? "" : "unavailable")
                        }
                      >
                        <img
                          src={data.icon_url_dark ? data.icon_url_dark : ""}
                          alt={data.name ? data.name : "Missing img"}
                        />
                      </div>
                      <span
                        className={
                          "name " + (data.uploadable ? "" : "unavailable")
                        }
                      >
                        {data.name ? addThreeDots(data.name, 35) : ""}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-5 col-md-6 col-lg-6">
                {data.name && <h2>{data.name}</h2>}
                <div className="details row">
                  <div className="col-sm-12 col-md-6">
                    <div className="planning">
                      <h4>{translation().orders.order}</h4>
                      {data.id && (
                        <div className="detail-row">
                          {data.order_id && (
                            <span className="detail">
                              <span className="detail-icon">
                                <ShoppingCartIcon fontSize="small" />
                              </span>{" "}
                              #{data.order_id}
                            </span>
                          )}
                        </div>
                      )}
                      {data.start && (
                        <div className="detail-row">
                          <span className="detail">
                            <span
                              className="detail-icon"
                              title={translation().orders.date}
                            >
                              <TodayIcon fontSize="small" />
                            </span>
                            {dateInTz(data.start, "localized-date")}
                          </span>
                          <span className="detail">
                            <span
                              className="detail-icon"
                              title={translation().orders.time}
                            >
                              <AccessTimeIcon fontSize="small" />
                            </span>
                            {dateInTz(data.start, "HH:mm")}
                          </span>
                        </div>
                      )}
                      <div className="detail-row">
                        {data.duration && (
                          <span className="detail">
                            <span
                              className="detail-icon"
                              title={translation().orders.duration}
                            >
                              <TimelapseIcon fontSize="small" />
                            </span>
                            {convertMinsToHrsMins(data.duration, "h")}
                          </span>
                        )}
                        {data.order &&
                          data.order.property &&
                          data.order.property.size_formatted && (
                            <span className="detail">
                              <span
                                className="detail-icon icon-surface"
                                title={translation().orders.size}
                              />
                              {data.order.property.size_formatted}
                            </span>
                          )}
                      </div>
                      {data.provider_name && (
                        <div className="detail-row">
                          <span className="detail">
                            <span
                              className="detail-icon"
                              title={translation().orders.provider_name}
                            >
                              <WorkIcon fontSize="small" />
                            </span>{" "}
                            <span>{data.provider_name}</span>
                          </span>
                        </div>
                      )}
                    </div>
                    {data.options && data.options.length > 0 ? (
                      <div className="options">
                        <h4>{translation().orders.options}</h4>
                        {this.renderOptions(data.options)}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <h4>{translation().orders.details}</h4>
                    {data.order && (
                      <div>
                        {data.order.company &&
                          data.order.company.commercial_name && (
                            <div className="detail-row">
                              <span className="detail">
                                <span className="detail-icon">
                                  <PersonIcon fontSize="small" />
                                </span>
                                {data.order.company.commercial_name}
                              </span>
                            </div>
                          )}
                        {data.order.property && data.order.property.address && (
                          <div className="detail-row">
                            <span className="detail">
                              <span className="detail-icon">
                                <RoomIcon fontSize="small" />
                              </span>{" "}
                              {data.order.property.address}
                            </span>
                          </div>
                        )}
                        {data && data.property_contact_comment && (
                          <div className="detail-row">
                            <span className="detail" style={{ marginTop: 12 }}>
                              <div className="detail-icon">
                                <CommentIcon fontSize="small" />
                              </div>
                              <p className="detail-comments">
                                {data.property_contact_comment}
                              </p>
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="clearfix" />
                </div>
              </div>
              <div
                className="col-xs-12 col-sm-2 col-md-2 col-lg-2"
                style={{ textAlign: "right" }}
              >
                {data.uploadable && (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={
                      !checkIsOrderReadyToSend() || upload_action.isLoading
                    }
                    onClick={this.handleUploadOrder}
                  >
                    <i className="material-icons" style={{ marginRight: 5 }}>
                      unarchive
                    </i>
                    <span>{translation().orders.send}</span>
                  </Button>
                )}
              </div>
              <div className="clearfix" />
              <MediaGroups
                clearMediaGroups={clearMediaGroups}
                downloadOrderProductMedias={downloadOrderProductMedias}
                getProductMediaGroups={getProductMediaGroups}
                mediaGroups={mediaGroups}
                product={data}
              />
              <div className="col-xs-12" style={{ textAlign: "right" }}>
                {data.uploadable && (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={
                      !checkIsOrderReadyToSend() || upload_action.isLoading
                    }
                    onClick={this.handleUploadOrder}
                  >
                    <i className="material-icons" style={{ marginRight: 5 }}>
                      unarchive
                    </i>
                    <span>{translation().orders.send}</span>
                  </Button>
                )}
              </div>
              <div className="clearfix" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      order: { data, isLoading },
    } = this.props;

    return (
      <div className="App-view Orders Order-view">
        {data ? (
          this.renderOrder(data)
        ) : !isLoading ? (
          <div className="data-empty">
            {translation().orders.order_not_found}
          </div>
        ) : (
          ""
        )}
        {isLoading && (
          <div className="center-loader">
            <CircularProgress color="secondary" size={35} />
          </div>
        )}
      </div>
    );
  }
}

Order.propTypes = {
  clearMediaGroups: PropTypes.func.isRequired,
  downloadOrderProductMedias: PropTypes.func.isRequired,
  getOrder: PropTypes.func.isRequired,
  getProductMediaGroups: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      oid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      pid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }).isRequired,
  }).isRequired,
  order: PropTypes.shape({
    data: PropTypes.shape(),
    isLoading: PropTypes.bool,
    mediaGroups: PropTypes.shape({
      list: PropTypes.arrayOf(PropTypes.any),
      isLoading: PropTypes.bool,
    }),
    upload_action: PropTypes.shape({
      error: PropTypes.any,
      isLoading: PropTypes.bool,
    }).isRequired,
    rating: PropTypes.shape({
      error: PropTypes.any,
      isLoading: PropTypes.bool,
    }).isRequired,
  }),
  orderProductMedias: PropTypes.shape(),
  uploadOrder: PropTypes.func.isRequired,
  user: PropTypes.shape().isRequired,
};

export default Order;
