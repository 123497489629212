// Uploader - Medias

export const storeMediaType = mediaType => ({
  type: 'STORE_MEDIA_TYPE',
  mediaType
});

export const storeMediaTypeMedia = (media, mediaTypeShortcode) => ({
  type: 'STORE_MEDIA_TYPE_MEDIA',
  media,
  mediaTypeShortcode,
});

export const storeMediaTypeProperty = (mediaUrl, mediaTypeShortcode, mediaProperty, mediaPropertyValue) => ({
  type: 'STORE_MEDIA_TYPE_MEDIA_PROPERTY',
  mediaUrl,
  mediaTypeShortcode,
  mediaProperty,
  mediaPropertyValue,
})

export const removeMediaTypeMedia = (mediaUrl, mediaTypeShortcode) => ({
  type: 'REMOVE_MEDIA_TYPE_MEDIA',
  mediaUrl,
  mediaTypeShortcode,
});

export const removeMediaTypeMedias = mediaTypeShortcode => ({
  type: 'REMOVE_MEDIA_TYPE_MEDIAS',
  mediaTypeShortcode
});

export const removeMediaTypes = () => ({
  type: 'REMOVE_MEDIA_TYPES'
});

export const storeOpmInitBuffer = status => ({
  type: 'STORE_OPM_INIT_BUFFER',
  status
});

export const storeOpmProductId = id => ({
  type: 'STORE_OPM_PRODUCT_ID',
  id
});

export const removeOpmInitBuffer = () => ({
  type: 'REMOVE_OPM_INIT_BUFFER'
});
