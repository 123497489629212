export const getOrder = () => ({
  type: "GET_ORDER"
});

export const getOrderSuccess = data => ({
  type: "GET_ORDER_SUCCESS",
  data
});

export const getOrderFailure = error => ({
  type: "GET_ORDER_FAILURE",
  error
});

export const getProductMediaGroups = () => ({
  type: 'GET_ORDER_PRODUCT_ID_MEDIA_GROUPS'
});

export const getProductMediaGroupsSuccess = data => ({
  type: 'GET_ORDER_PRODUCT_ID_MEDIA_GROUPS_SUCCESS',
  data
});

export const getProductMediaGroupsFailure = error => ({
  type: 'GET_ORDER_PRODUCT_ID_MEDIA_GROUPS_FAILURE',
  error
});

export const postOrderProductIdMedias = () => ({
  type: "POST_ORDER_PRODUCT_ID_MEDIAS"
});

export const postOrderProductIdMediasSuccess = () => ({
  type: "POST_ORDER_PRODUCT_ID_MEDIAS_SUCCESS"
});

export const postOrderProductIdMediasFailure = error => ({
  type: "POST_ORDER_PRODUCT_ID_MEDIAS_FAILURE",
  error
});

export const rateClient = () => ({
  type: "RATE_CLIENT",
});

export const rateClientSuccess = () => ({
  type: "RATE_CLIENT_SUCCESS",
});

export const rateClientError = error => ({
  type: "RATE_CLIENT_ERROR",
  error
});
