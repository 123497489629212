import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { reset } from "redux-form";

import { fetchApi } from "../../helpers/functions/api";
import {
  refreshTasks,
  getTasks,
  getTasksSuccess,
  getTasksError,
  getCompletionStatuses,
  getCompletionStatusesSuccess,
  getCompletionStatusesError,
} from "../../redux/actions/tasksActions";
import { enqueueSnackbar } from "../../redux/actions/notificationsActions";
import translation from "../../translation/translation";
import config from "../../config";
import Tasks from "../../views/tasks/Tasks";

const mapStateToProps = (state) => ({
  tasks: state.tasks,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getTasks(next = null, isRefresh = false, search = null) {
      const query = { filters: "archived|nu", sort: "due", limit: 25 };

      isRefresh ? dispatch(refreshTasks()) : dispatch(getTasks(next, search));

      const params = next ? null : search ? { ...query, search } : { ...query };

      fetchApi(
        "get",
        next ? next.replace(config.api_url, "") : `users/self/tasks`,
        params,
        null,
        null,
        (data, paging) => {
          dispatch(getTasksSuccess(data, paging));
        },
        (error) => {
          dispatch(getTasksError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().tasks.get_list_error,
                status: error.response.status || undefined,
                options: {
                  variant: "error",
                },
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().tasks.get_list_error,
                options: {
                  variant: "error",
                },
              })
            );
          }
        }
      );
    },
    getCompletionStatuses() {
      dispatch(getCompletionStatuses());

      fetchApi(
        "get",
        "completion-statuses",
        null,
        null,
        null,
        (data) => {
          dispatch(getCompletionStatusesSuccess(data));
        },
        (error) => {
          dispatch(getCompletionStatusesError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().tasks.get_statuses_error,
                status: error.response.status || undefined,
                options: {
                  variant: "error",
                },
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().tasks.get_statuses_error,
                options: {
                  variant: "error",
                },
              })
            );
          }
        }
      );
    },
    updateCompletionStatus(tid, csid, onSuccess, onError) {
      fetchApi(
        "put",
        `users/self/tasks/${tid}`,
        null,
        { completion_status_id: csid },
        null,
        (data) => {
          onSuccess(data);
        },
        (error) => {
          onError();

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().tasks.update_status_error,
                status: error.response.status || undefined,
                options: {
                  variant: "error",
                },
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().tasks.update_status_error,
                options: {
                  variant: "error",
                },
              })
            );
          }
        }
      );
    },
    uploadAttachedFiles(tid, files, onSuccess, onError) {
      fetchApi(
        "put",
        `users/self/tasks/${tid}`,
        null,
        { attachments: files },
        null,
        (success) => {
          onSuccess(success);
          dispatch(
            enqueueSnackbar({
              message: translation().tasks.attachments.upload_success,
              options: {
                variant: "success",
              },
            })
          );
        },
        () => {
          onError();
          dispatch(
            enqueueSnackbar({
              message: translation().tasks.attachments.upload_error,
              options: {
                variant: "error",
              },
            })
          );
        }
      );
    },
    resetSearchForm() {
      dispatch(reset("tasksSearchForm"));
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tasks));
