import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  reduxForm
} from 'redux-form';
import * as moment from 'moment';
import 'moment-timezone';
import countries from 'country-list';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import MuiSelectWithLabelField from '../../../../components/commons/fields/MuiSelectWithLabelField';

import config from '../../../../config';
import validate from './validate';

function renderOptions(items) {

  if (!items) return;

  let menuItems = [];

  if (items.length > 0) {
    for (let i = 0; i < items.length; i++) {
      menuItems.push(
        <option key={i} value={items[i].value}>
          {items[i].label}
        </option>
      );
    }
  }

  return menuItems;
}

class SettingsForm extends Component {

  constructor(props) {
    super(props);

    this.timezonesArray = [];
    this.currencyArray = [];
    this.localeArray = [];
    this.countriesArray = [];
    this.metricsArray = [
      { label: props.labels.metric || "Metric", value: "metric" },
      { label: props.labels.imperial || "Imperial", value: "imperial" },
      { label: props.labels.imperial_us || "Imperial (US)", value: "imperial_us" }
    ];

    const momentTzArray = moment.tz.names();

    for (let i = 0; i < momentTzArray.length; i++) {
      this.timezonesArray.push({
        label: momentTzArray[i],
        value: momentTzArray[i]
      });
    }

    for (let i = 0; i < config.open_countries_lang.length; i++) {
      this.localeArray.push({
        label: config.open_countries_lang[i].name,
        value: config.open_countries_lang[i].code
      });
    }

    for (let i = 0; i < config.open_countries_currencies.length; i++) {
      this.currencyArray.push({
        label: config.open_countries_currencies[i].code + " - " + config.open_countries_currencies[i].name,
        value: config.open_countries_currencies[i].code
      });
    }

    for (let i = 0; i < countries.getData().length; i++) {
      this.countriesArray.push({
        label: countries.getData()[i].name,
        value: countries.getData()[i].code
      });
    }
  }

  render() {

    const {
      buttonLabel,
      settings,
      handleSubmit,
      labels
    } = this.props;

    return (
      <form onSubmit={handleSubmit} autoComplete="off">
        <div className="field-wrapper">
          <Field
            name="unit_system"
            id="unit_system"
            label={labels.unit_system}
            fullWidth={true}
            component={MuiSelectWithLabelField}
          >
            <option value="" />
            {renderOptions(this.metricsArray)}
          </Field>
        </div>
        <div className="field-wrapper">
          <Field
            name="language_locale"
            id="language_locale"
            label={labels.language}
            fullWidth={true}
            component={MuiSelectWithLabelField}
          >
            <option value="" />
            {renderOptions(this.localeArray)}
          </Field>
        </div>
        <div className="field-wrapper">
          <Field
            name="timezone_code"
            id="timezone_code"
            label={labels.timezone}
            fullWidth={true}
            component={MuiSelectWithLabelField}
          >
            <option value="" />
            {renderOptions(this.timezonesArray)}
          </Field>
        </div>
        <div className="field-wrapper">
          <Field
            name="currency_isocode"
            id="currency_isocode"
            label={labels.currency}
            fullWidth={true}
            component={MuiSelectWithLabelField}
          >
            <option value="" />
            {renderOptions(this.currencyArray)}
          </Field>
        </div>
        <div className="field-wrapper">
          <Field
            name="residence_country_isocode"
            id="residence_country_isocode"
            label={labels.residence_country}
            fullWidth={true}
            component={MuiSelectWithLabelField}
          >
            <option value="" />
            {renderOptions(this.countriesArray)}
          </Field>
        </div>
        <div className="field-wrapper">
          <Field
            name="citizen_country_isocode"
            id="citizen_country_isocode"
            label={labels.citizen_country}
            fullWidth={true}
            component={MuiSelectWithLabelField}
          >
            <option value="" />
            {renderOptions(this.countriesArray)}
          </Field>
        </div>
        <div className="submit-wrapper">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={settings.loading}
            style={{minWidth: 150}}
          >
            { settings.loading
              ? <CircularProgress size={20} color='secondary' />
              : <span>{buttonLabel}</span>
            }
          </Button>
        </div>
      </form>
    );
  }
}

SettingsForm.propTypes = {
  settings: PropTypes.shape({
    loading: PropTypes.bool,
    error: PropTypes.any,
  }).isRequired,
  labels: PropTypes.shape({
    metric: PropTypes.string.isRequired,
    imperial: PropTypes.string.isRequired,
    imperial_us: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    timezone: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    residence_country: PropTypes.string.isRequired,
    citizen_country: PropTypes.string.isRequired,
    unit_system: PropTypes.string.isRequired,
  }).isRequired,
  buttonLabel: PropTypes.node.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'updateSettingsForm',
  enableReinitialize: true,
  validate
})(SettingsForm);
