import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reset, stopSubmit } from 'redux-form';

import { fetchApi } from '../../helpers/functions/api';
import { getLocale } from '../../helpers/functions/cookies';
import { scrollToAnchor } from '../../helpers/functions/jquery';
import { getUserFromStore } from '../../helpers/functions/user';

import {
  updateProfile, updateProfileSuccess, updateProfileFailure,
  updateSettings, updateSettingsSuccess, updateSettingsFailure,
  updatePassword, updatePasswordSuccess, updatePasswordFailure
} from '../../redux/actions/profileActions';
import { enqueueSnackbar } from '../../redux/actions/notificationsActions';
import { storeUser } from '../../redux/actions/userActions';

import translation from '../../translation/translation';

import Profile from '../../views/profile/Profile';

const mapStateToProps = (state) => ({
  profile: state.profile,
  user: state.user
})

const mapDispatchToProps = dispatch => {

  function _getUserTimezone() {
    if (getUserFromStore() && getUserFromStore().timezone_code)
      return getUserFromStore().timezone_code;
    else
      return null;
  }

  return {
    updateUser(values) {

      if (!values) return;

      dispatch(updateProfile());

      const successMessage = translation().profile.form.callbacks.personal_info.success;
      const errorMessage = translation().profile.form.callbacks.personal_info.error;

      fetchApi(
        "put",
        "users/self",
        null,
        values,
        null,
        user => {
          dispatch(storeUser(user));
          dispatch(updateProfileSuccess());
          dispatch(enqueueSnackbar({
            message: successMessage,
            options: {
              variant: 'success',
            },
          }));
        },
        error => {
          if (error && error.response) {

            if (error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
              const validationErrors = {};
              const errorsArray = error.response.data.errors;
              const scrollAnchor = document.getElementsByName(errorsArray[0].propertyPath);

              if (scrollAnchor[0])
                scrollToAnchor(scrollAnchor[0])

              for (let i = 0; i < errorsArray.length; i++) {
                if (errorsArray[i].propertyPath)
                  validationErrors[errorsArray[i].propertyPath] = errorsArray[i].message;
              }

              dispatch(updateProfileFailure(errorsArray));
              dispatch(stopSubmit(
                'updateProfileForm',
                validationErrors
              ));
            }

            dispatch(enqueueSnackbar({
              message: error.response.data && error.response.data.detail ? error.response.data.detail : errorMessage,
              options: {
                variant: 'error',
              },
            }));
          }
          else {
            dispatch(updateProfileFailure(errorMessage));
            dispatch(enqueueSnackbar({
              message: errorMessage,
              options: {
                variant: 'error',
              },
            }));
          }
        }
      );
    },
    updateSettings(values) {

      if (!values) return;

      dispatch(updateSettings());

      const successMessage = translation().profile.form.callbacks.settings.success;
      const errorMessage = translation().profile.form.callbacks.settings.error;
      const userTimezone = _getUserTimezone();
      const userLocale = getLocale();

      fetchApi(
        "put",
        "users/self",
        null,
        values,
        null,
        user => {
          dispatch(storeUser(user));
          dispatch(updateSettingsSuccess());
          dispatch(enqueueSnackbar({
            message: successMessage,
            options: {
              variant: 'success',
            },
          }));

          if (values.language_locale && (values.language_locale !== userLocale))
            window.location.reload();

          if (values.timezone_code && (values.timezone_code !== userTimezone))
            window.location.reload();

        },
        error => {
          if (error && error.response) {

            if (error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
              const validationErrors = {};
              const errorsArray = error.response.data.errors;
              const scrollAnchor = document.getElementsByName(errorsArray[0].propertyPath);

              if (scrollAnchor[0])
                scrollToAnchor(scrollAnchor[0])

              for (let i = 0; i < errorsArray.length; i++) {
                if (errorsArray[i].propertyPath)
                  validationErrors[errorsArray[i].propertyPath] = errorsArray[i].message;
              }

              dispatch(updateSettingsFailure(errorsArray));
              dispatch(stopSubmit(
                'updateSettingsForm',
                validationErrors
              ));
            }

            dispatch(enqueueSnackbar({
              message: error.response.data && error.response.data.detail ? error.response.data.detail : errorMessage,
              options: {
                variant: 'error',
              },
            }));
          }
          else {
            dispatch(updateSettingsFailure(errorMessage));
            dispatch(enqueueSnackbar({
              message: errorMessage,
              options: {
                variant: 'error',
              },
            }));
          }
        }
      );
    },
    updatePassword(values) {

      if (!values) return;

      dispatch(updatePassword());

      const successMessage = translation().profile.form.callbacks.password.success;
      const errorMessage = translation().profile.form.callbacks.password.error;

      fetchApi(
        "put",
        "users/self/password",
        null,
        values,
        null,
        () => {
          dispatch(updatePasswordSuccess());
          dispatch(enqueueSnackbar({
            message: successMessage,
            options: {
              variant: 'success',
            },
          }));
          dispatch(reset('updatePasswordForm'));
        },
        error => {
          if (error && error.response) {

            if (error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
              const validationErrors = {};
              const errorsArray = error.response.data.errors;

              for (let i = 0; i < errorsArray.length; i++) {
                if (errorsArray[i].propertyPath)
                  validationErrors[errorsArray[i].propertyPath] = errorsArray[i].message;
              }

              dispatch(updatePasswordFailure(errorsArray));
              dispatch(stopSubmit(
                'updatePasswordForm',
                validationErrors
              ));
            }

            dispatch(enqueueSnackbar({
              message: error.response.data && error.response.data.detail ? error.response.data.detail : errorMessage,
              options: {
                variant: 'error',
              },
            }));
          }
          else {
            dispatch(updatePasswordFailure(errorMessage));
            dispatch(enqueueSnackbar({
              message: errorMessage,
              options: {
                variant: 'error',
              },
            }));
          }
        }
      );
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile));
