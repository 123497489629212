import React, { Component } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";

import config from "../../config";
import translation from "../../translation/translation";

const drawerWidth = 215;

const styles = (theme) => ({
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.down("xs")]: {
      width: "70%",
    },
  },
  envLabel: {
    backgroundColor: "#42a5f5",
    borderRadius: 50,
    padding: "4px 12px",
    lineHeight: 1,
    fontSize: 12,
  },
});

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      drawerOpen: false,
      isContactOpen: false,
      isTermsOpen: false,
    };

    this.handleToggleDrawer = this.handleToggleDrawer.bind(this);
    this.handleSignOut = this.handleSignOut.bind(this);
    this.renderEnvironmentLabel = this.renderEnvironmentLabel.bind(this);
  }

  handleToggleDrawer() {
    if (!this.state.drawerOpen) this.setState({ drawerOpen: true });
    else this.setState({ drawerOpen: false });
  }

  handleSignOut() {
    const { signOut } = this.props;

    signOut();
  }

  renderEnvironmentLabel() {
    const { classes } = this.props;

    switch (config.api_env) {
      case "dev":
        return <div className={classes.envLabel}>{config.api_env}</div>;
      case "sandbox":
        return <div className={classes.envLabel}>{config.api_env}</div>;
      case "local":
        return <div className={classes.envLabel}>{config.api_env}</div>;
      default:
        return false;
    }
  }

  render() {
    const { classes, user } = this.props;

    const { drawerOpen } = this.state;

    return (
      <div className="Header">
        <AppBar
          position="fixed"
          color="primary"
          id="AppHeader"
          style={{ zIndex: 2000 }}
        >
          <Toolbar>
            <div className="logo-link">
              <a href="/">
                <img
                  className="app-logo"
                  src="/assets/images/commons/logo-ls-provider.svg"
                  alt={config.site_name}
                />
              </a>
            </div>
            {this.renderEnvironmentLabel()}
            <IconButton
              style={{ color: "white", flex: "0 1 auto", marginLeft: "auto" }}
              aria-label="Open drawer"
              onClick={this.handleToggleDrawer}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={this.handleToggleDrawer}
          className="header-drawer"
          style={{ zIndex: 2100 }}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className="user-area">
            <div className="col-xs-3" style={{ padding: 0, paddingRight: 12 }}>
              <img
                className="user-avatar"
                src={
                  user.picture_url
                    ? user.picture_url + "?client_id=" + config.api_key
                    : "/assets/images/commons/default-user-picture.png"
                }
                alt="Profile pic"
              />
            </div>
            <div
              className="col-xs-9"
              style={{ textAlign: "right", padding: 0, paddingLeft: 5 }}
            >
              <div className="user-name">
                {user && user.first_name ? user.first_name : ""}
              </div>
              <span
                role="button"
                className="user-logout"
                aria-label="Sign out"
                onClick={this.handleSignOut}
              >
                {translation().header.logout}
              </span>
            </div>
            <div className="clearfix" />
          </div>
          <Divider />
          <div
            tabIndex={0}
            role="button"
            style={{ outline: 0 }}
            onClick={this.handleToggleDrawer}
            onKeyDown={this.handleToggleDrawer}
          >
            <List component="nav">
              <NavLink to={`/order-products`}>
                <ListItem button>
                  <ListItemIcon>
                    <span className="link-icon icon-upload" />
                  </ListItemIcon>
                  <ListItemText disableTypography>
                    {translation().header.orders}
                  </ListItemText>
                </ListItem>
              </NavLink>
              <NavLink to={`/profile`}>
                <ListItem button>
                  <ListItemIcon>
                    <span className="link-icon icon-connecte" />
                  </ListItemIcon>
                  <ListItemText disableTypography>
                    {translation().header.profile}
                  </ListItemText>
                </ListItem>
              </NavLink>
              <NavLink to={`/tasks`}>
                <ListItem button>
                  <ListItemIcon>
                    <span className="link-icon icon-selectionne" />
                  </ListItemIcon>
                  <ListItemText disableTypography>
                    {translation().header.tasks}
                  </ListItemText>
                </ListItem>
              </NavLink>
            </List>
          </div>
        </Drawer>
      </div>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.shape().isRequired,
  signOut: PropTypes.func.isRequired,
  user: PropTypes.shape({
    company_admin: PropTypes.bool,
    first_name: PropTypes.string.isRequired,
    picture_url: PropTypes.string
  }).isRequired
};

export default withStyles(styles)(Header);
