const initialState = {
  informations: {
    loading: false,
    error: null
  },
  password: {
    loading: false,
    error: null
  },
  settings: {
    loading: false,
    error: null
  }
};

export default (state = initialState, action) => {

  switch (action.type) {
    case 'CLEAR_REDUCERS':
      return initialState;
    case 'UPDATE_INFORMATIONS':
      return {
        ...state,
        informations: {
          loading: true,
          error: null
        }
      };
    case 'UPDATE_INFORMATIONS_SUCCESS':
      return {
        ...state,
        informations: {
          loading: false,
          error: null
        }
      };
    case 'UPDATE_INFORMATIONS_FAILURE':
      return {
        ...state,
        informations: {
          loading: false,
          error: action.error
        }
      };
    case 'UPDATE_PASSWORD':
      return {
        ...state,
        password: {
          loading: true,
          error: null
        }
      };
    case 'UPDATE_PASSWORD_SUCCESS':
      return {
        ...state,
        password: {
          loading: false,
          error: null
        }
      };
    case 'UPDATE_PASSWORD_FAILURE':
      return {
        ...state,
        password: {
          loading: false,
          error: action.error
        }
      };
    case 'UPDATE_SETTINGS':
      return {
        ...state,
        settings: {
          loading: true,
          error: null
        }
      };
    case 'UPDATE_SETTINGS_SUCCESS':
      return {
        ...state,
        settings: {
          loading: false,
          error: null
        }
      };
    case 'UPDATE_SETTINGS_FAILURE':
      return {
        ...state,
        settings: {
          loading: false,
          error: action.error
        }
      };
    default:
      return state;
  }
};
