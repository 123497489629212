/* eslint-disable react/jsx-no-bind */
import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Badge,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import { AttachFile, Check } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";

import translation from "../../../translation/translation";
import DropContainer from "../../../components/commons/minimalDropper/DropContainer";

const styles = () => ({
  badge: {
    padding: 0,
    fontSize: "0.61rem",
    lineHeight: 0,
    height: 16,
    minWidth: 16,
    right: 10,
    top: 10,
  },
  grow: {
    flexGrow: 1,
  },
  dialogLoading: {
    display: "flex",
    alignItems: "center",
    padding: "0px 4px",
  },
});

function Attachments(props) {
  const { attachments, classes, disabled, onUpload, taskId } = props;

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState(attachments);
  const [saved, setSaved] = useState(attachments);

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
    setFiles(saved);
  }

  function handleChange(e) {
    setFiles(e);
  }

  function handleUpload() {
    if (!taskId) return;

    setLoading(true);

    onUpload(
      taskId,
      files,
      (success) => {
        setLoading(false);

        if (success && success.attachments) {
          setSaved(success.attachments);
          setOpen(false);
        }
      },
      () => {
        setLoading(false);
        setFiles(saved);
      }
    );
  }

  function handleScroll(e) {
    e.stopPropagation();
  }

  return (
    <>
      <Badge
        badgeContent={saved.length}
        color="primary"
        classes={{ badge: classes.badge }}
      >
        <IconButton onClick={handleOpen} disabled={disabled}>
          <AttachFile fontSize="small" />
        </IconButton>
      </Badge>
      {open && (
        <Dialog
          open
          onClose={handleClose}
          fullWidth
          maxWidth="lg"
          disableBackdropClick={loading}
        >
          <DialogTitle>{translation().tasks.table.attachments}</DialogTitle>
          <DialogContent onScroll={handleScroll}>
            <DropContainer
              accept="*"
              defaultFiles={saved}
              onChange={handleChange}
              disabled={loading}
              showFilesName
              maxFiles={10}
            />
          </DialogContent>
          <DialogActions>
            {loading && (
              <div className={classes.dialogLoading}>
                <CircularProgress size={25} style={{ marginRight: 8 }} />
                <Typography color="textSecondary">
                  {translation().tasks.attachments.loading_text}
                </Typography>
              </div>
            )}
            <div className={classes.grow} />
            <Button disabled={loading} onClick={handleClose}>
              {translation().tasks.attachments.close}
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleUpload}
              disabled={loading}
            >
              <Check fontSize="small" />
              {translation().tasks.attachments.confirm}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

Attachments.defaultProps = {
  attachments: [],
  disabled: false,
};

Attachments.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.shape()),
  classes: PropTypes.shape().isRequired,
  disabled: PropTypes.bool,
  onUpload: PropTypes.func.isRequired,
  taskId: PropTypes.number.isRequired,
};

export default withStyles(styles)(Attachments);
