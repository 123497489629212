const initialState = {
  data: null,
  isLoading: false,
  error: null,
  mediaGroups: {
    list: [],
    isLoading: false,
    error: null
  },
  upload_action: {
    isLoading: false,
    error: null
  },
  rating: {
    isLoading: false,
    error: null
  }
};

export default (state = initialState, action) => {

  switch (action.type) {
    case 'CLEAR_REDUCERS':
      return initialState;
    case 'GET_ORDER':
      return {
        ...state,
        data: null,
        isLoading: true
      };
    case 'GET_ORDER_SUCCESS':
      return {
        ...state,
        data: action.data,
        error: null,
        isLoading: false
      };
    case 'GET_ORDER_FAILURE':
      return {
        ...state,
        data: null,
        error: action.error,
        isLoading: false
      };
    case 'CLEAR_MEDIA_GROUPS':
      return {
        ...state,
        mediaGroups: initialState.mediaGroups
      };
    case 'GET_ORDER_PRODUCT_ID_MEDIA_GROUPS':
      return {
        ...state,
        mediaGroups: {
          list: [],
          isLoading: true,
          error: null
        }
      };
    case 'GET_ORDER_PRODUCT_ID_MEDIA_GROUPS_SUCCESS':
      return {
        ...state,
        mediaGroups: {
          list: action.data,
          isLoading: false,
          error: null
        }
      };
    case 'GET_ORDER_PRODUCT_ID_MEDIA_GROUPS_FAILURE':
      return {
        ...state,
        mediaGroups: {
          ...state.mediaGroups,
          isLoading: false,
          error: null
        }
      };
    case 'POST_ORDER_PRODUCT_ID_MEDIAS':
      return {
        ...state,
        upload_action: {
          isLoading: true,
          error: null
        }
      }
    case 'POST_ORDER_PRODUCT_ID_MEDIAS_SUCCESS':
      return {
        ...state,
        upload_action: {
          isLoading: false,
          error: null
        }
      }
    case 'POST_ORDER_PRODUCT_ID_MEDIAS_FAILURE':
      return {
        ...state,
        upload_action: {
          isLoading: false,
          error: action.error
        }
      }
    case 'RATE_CLIENT':
      return {
        ...state,
        rating: {
          isLoading: true,
          error: null
        }
      }
    case 'RATE_CLIENT_SUCCESS':
      return {
        ...state,
        rating: {
          isLoading: false,
          error: null
        }
      }
    case 'RATE_CLIENT_ERROR':
      return {
        ...state,
        rating: {
          isLoading: false,
          error: action.error
        }
      }
    default:
      return state;
  }
};
