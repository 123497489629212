/* eslint-disable react/sort-comp */
/* eslint-disable react/jsx-no-bind */
import classNames from "classnames";
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Button,
  CircularProgress,
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Refresh, Warning } from "@material-ui/icons";

import Attachments from "./components/Attachments";
import CompletionStatus from "./components/CompletionStatus";
import PriorityFlag from "./components/PriorityFlag";
import SearchForm from "./components/SearchForm";
import { handleInfiniteScroll } from "../../helpers/functions/jquery";
import {
  dateInTz,
  getCurrentDateUtc,
  toUtcTimestamp,
} from "../../helpers/functions/dates";
import translation from "../../translation/translation";

class Tasks extends Component {
  constructor(props) {
    super(props);

    this.handleGetNext = this.handleGetNext.bind(this);
    this.handleRefreshTasks = this.handleRefreshTasks.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.renderOverdueIcon = this.renderOverdueIcon.bind(this);
    this.renderTable = this.renderTable.bind(this);
  }

  handleScroll() {
    const {
      getTasks,
      tasks: { loading, paging },
    } = this.props;

    if (handleInfiniteScroll(null, 200)) {
      if (paging && paging.next && !loading) {
        getTasks(paging.next);
      }
    }
  }

  componentWillMount() {
    const { getCompletionStatuses, getTasks } = this.props;

    getCompletionStatuses();
    getTasks();

    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleSearch(values) {
    const { getTasks } = this.props;

    getTasks(null, false, values && values.search ? values.search : "");
  }

  handleRefreshTasks() {
    const { getTasks, resetSearchForm } = this.props;

    resetSearchForm();
    getTasks(null, true);
  }

  handleGetNext() {
    const {
      getTasks,
      tasks: { loading, paging },
    } = this.props;

    if (paging && paging.next && !loading) {
      getTasks(paging.next);
    }
  }

  renderOverdueIcon(expected) {
    const now = getCurrentDateUtc();

    if (toUtcTimestamp(expected) < toUtcTimestamp(now)) {
      return (
        <Warning
          color="error"
          fontSize="small"
          style={{ marginLeft: 4, marginTop: -1, verticalAlign: "middle" }}
        />
      );
    } else {
      return false;
    }
  }

  renderTable(data) {
    const {
      tasks: { completion_statuses },
      uploadAttachedFiles,
      updateCompletionStatus,
    } = this.props;

    if (data && data.length > 0) {
      const rows = [];

      for (let i = 0; i < data.length; i++) {
        const row = {};

        if (data[i].id) {
          row["id"] = data[i].id;
        } else {
          row["id"] = "-";
        }

        if (data[i].due) {
          row["due"] = data[i].due;
        } else {
          row["due"] = "-";
        }

        if (data[i].attachments) {
          row["attachments"] = data[i].attachments;
        } else {
          row["attachments"] = [];
        }

        if (data[i].label) {
          row["label"] = data[i].label;
        } else {
          row["label"] = "-";
        }

        if (data[i].priority) {
          row["priority"] = data[i].priority;
        } else {
          row["priority"] = "-";
        }

        if (data[i].description) {
          row["description"] = data[i].description;
        } else {
          row["description"] = "-";
        }

        if (data[i].completion_status) {
          row["completion_status"] = data[i].completion_status;
        } else {
          row["completion_status"] = "-";
        }

        if (data[i].order) {
          row["order_id"] =
            data[i].order && data[i].order.id ? data[i].order.id : "-";
        } else {
          row["order_id"] = "-";
        }

        if (data[i].order_product) {
          row["order_product_id"] =
            data[i].order_product && data[i].order_product.id
              ? data[i].order_product.id
              : "-";
        } else {
          row["order_product_id"] = "-";
        }

        rows.push(row);
      }

      return (
        <div className="table-wrapper">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{translation().tasks.table.priority}</TableCell>
                <TableCell align="right">
                  {translation().tasks.table.order}
                </TableCell>
                <TableCell>{translation().tasks.table.label}</TableCell>
                <TableCell>{translation().tasks.table.desc}</TableCell>
                <TableCell>{translation().tasks.table.due}</TableCell>
                <TableCell>
                  {translation().tasks.table.completion_status}
                </TableCell>
                <TableCell align="right">
                  {translation().tasks.table.order_product}
                </TableCell>
                <TableCell align="right">
                  {translation().tasks.table.attachments}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, k) => {
                return (
                  <TableRow key={k}>
                    <TableCell component="th" scope="row">
                      <PriorityFlag value={row.priority} />
                    </TableCell>
                    <TableCell align="right">
                      {row.order_id !== "-" && row.order_product_id !== "-" ? (
                        <Link
                          color="secondary"
                          href={`orders/${row.order_id}/products/${row.order_product_id}`}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {row.order_id}
                        </Link>
                      ) : (
                        row.order_id
                      )}
                    </TableCell>
                    <TableCell style={{ fontWeight: 500 }}>
                      {row.label}
                    </TableCell>
                    <TableCell style={{ maxWidth: 500 }}>
                      {row.description.length > 0
                        ? row.description.split("\n").map((item, key) => (
                            <span key={key}>
                              {item}
                              <br />
                            </span>
                          ))
                        : row.description}
                    </TableCell>
                    <TableCell
                      className={classNames({
                        overdue: Boolean(this.renderOverdueIcon(row.due)),
                      })}
                    >
                      {dateInTz(row.due, "D MMM HH:mm")}
                      {row.due !== "-" && this.renderOverdueIcon(row.due)}
                    </TableCell>
                    <TableCell
                      style={{ padding: 0, position: "relative", minWidth: 90 }}
                    >
                      {row.completion_status !== "-" ? (
                        <CompletionStatus
                          onChange={updateCompletionStatus}
                          statuses={completion_statuses.data}
                          taskId={row.id}
                          value={row.completion_status}
                        />
                      ) : (
                        row.completion_status
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {row.order_id !== "-" && row.order_product_id !== "-" ? (
                        <Link
                          color="secondary"
                          href={`orders/${row.order_id}/products/${row.order_product_id}`}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {row.order_product_id}
                        </Link>
                      ) : (
                        row.order_product_id
                      )}
                    </TableCell>
                    <TableCell align="right">
                      <Attachments
                        attachments={row.attachments}
                        onUpload={uploadAttachedFiles}
                        taskId={row.id}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      );
    }
  }

  render() {
    const {
      tasks: { data, loading, paging },
    } = this.props;

    return (
      <div className="App-view Tasks">
        <div className="page-title">
          <div className="row">
            <div className="col-xs-12 col-sm-2 col-md-4" />
            <div className="col-xs-12 col-sm-4 col-md-4">
              <h3>{translation().tasks.page_title}</h3>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4 col-search">
              <IconButton
                onClick={this.handleRefreshTasks}
                className="refresh-button"
              >
                <Refresh fontSize="small" />
              </IconButton>
              <SearchForm onSubmit={this.handleSearch} />
            </div>
            <div className="clearfix" />
          </div>
        </div>
        <div className="tasks-container">
          <Paper>
            {data && data.length > 0 ? (
              this.renderTable(data)
            ) : !loading ? (
              <div className="data-empty">{translation().tasks.empty}</div>
            ) : (
              ""
            )}
          </Paper>
          {loading && (
            <div className="center-loader" style={{ marginTop: 24 }}>
              <CircularProgress color="secondary" size={35} />
            </div>
          )}
          {data && paging && paging.next && !loading && (
            <div className="center-loader" style={{ marginTop: 24 }}>
              <Button variant="contained" onClick={this.handleGetNext}>
                {translation().tasks.load_more}
              </Button>
            </div>
          )}
          {!loading && paging && !paging.next && paging.prev && (
            <div className="center-loader" style={{ marginTop: 12 }}>
              <Typography color="textSecondary" align="center" variant="body2">
                {translation().tasks.paging_done}
              </Typography>
            </div>
          )}
          {!loading && !paging && data && data.length > 0 ? (
            <div className="center-loader" style={{ marginTop: 12 }}>
              <Typography color="textSecondary" align="center" variant="body2">
                {translation().tasks.paging_done}
              </Typography>
            </div>
          ) : (
            false
          )}
        </div>
      </div>
    );
  }
}

Tasks.propTypes = {
  getCompletionStatuses: PropTypes.func.isRequired,
  getTasks: PropTypes.func.isRequired,
  tasks: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape()),
    error: PropTypes.bool,
    loading: PropTypes.bool,
    paging: PropTypes.shape({
      next: PropTypes.string,
      prev: PropTypes.string,
    }),
    completion_statuses: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape()),
      loading: PropTypes.bool,
      error: PropTypes.any,
    }),
  }),
  resetSearchForm: PropTypes.func.isRequired,
  updateCompletionStatus: PropTypes.func.isRequired,
  uploadAttachedFiles: PropTypes.func.isRequired,
};

export default Tasks;
