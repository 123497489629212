import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import MuiTextField from '../../../../components/commons/fields/MuiTextField';

import validate from './validate';

class PasswordForm extends Component {

  render() {

    const {
      buttonLabel,
      handleSubmit,
      labels,
      password
    } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <div className="field-wrapper">
          <Field
            name="old_password"
            id="oldPassword"
            type="password"
            label={labels.current_password}
            component={MuiTextField}
            required={true}
          />
        </div>
        <div className="field-wrapper">
          <Field
            name="new_password"
            id="newPassword"
            type="password"
            label={labels.new_password}
            component={MuiTextField}
            required={true}
          />
        </div>
        <div className="field-wrapper">
          <Field
            name="confirmPwd"
            id="confirmPwd"
            type="password"
            label={labels.confirm_new_password}
            component={MuiTextField}
            required={true}
          />
        </div>
        <div className="submit-wrapper">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={password.loading}
            style={{minWidth: 150}}
          >
            {
              password.loading
                ? <CircularProgress size={20} color='secondary' />
                : <span>{buttonLabel}</span>
            }
          </Button>
        </div>
    </form>
    );
  }
}

PasswordForm.propTypes = {
  password: PropTypes.shape({
    loading: PropTypes.bool,
    error: PropTypes.any,
  }).isRequired,
  labels: PropTypes.shape({
    current_password: PropTypes.string.isRequired,
    new_password: PropTypes.string.isRequired,
    confirm_new_password: PropTypes.string.isRequired,
  }).isRequired,
  buttonLabel: PropTypes.node.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'updatePasswordForm',
  validate
})(PasswordForm);
