import React, { Component } from "react";
import PropTypes from "prop-types";

import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";

import { generateHash } from "../../../helpers/functions/utils";
import { setMediaTypeProperty } from "../../../helpers/functions/redux";

import ApiAutoCompleteComponent from "../../../components/commons/fields/ApiAutoCompleteComponent";

import config from "../../../config";

function DownloadIcon(props) {
  const { path, onClick } = props;

  function handleClick() {
    if (path) {
      onClick(path);
    }
  }

  return (
    <IconButton
      size="small"
      aria-label="Download"
      className="file-card-action"
      // eslint-disable-next-line react/jsx-no-bind
      onClick={handleClick}
    >
      <CloudDownloadIcon fontSize="small" />
    </IconButton>
  );
}

DownloadIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  path: PropTypes.any.isRequired,
};

class FileCard extends Component {
  constructor(props) {
    super(props);

    this.handleDeleteFile = this.handleDeleteFile.bind(this);
    this.handleSelectValue = this.handleSelectValue.bind(this);
  }

  handleDeleteFile() {
    const { file, onDeleteFile } = this.props;

    if (file && onDeleteFile) onDeleteFile(file);
  }

  handleSelectValue(value) {
    const { file } = this.props;

    /**
     * Specific part of code for order product moderation
     */
    if (value && typeof value === "object")
      setMediaTypeProperty(
        file.url,
        file.media_type.shortcode,
        "media_template_id",
        value.id
      );
    else
      setMediaTypeProperty(
        file.url,
        file.media_type.shortcode,
        "media_template_id",
        "null"
      );
  }

  handleDownloadMedia(url) {
    if (!url) return;

    const downloadIframe = document.getElementById("frame-download");
    const iframesContainer = document.getElementById("iframes-container");

    /*
     * If iframe already exists we only change url, if not we create it
     */
    if (downloadIframe) {
      downloadIframe.setAttribute(
        "src",
        `${url}/download?client_id=${config.api_key}`
      );
    } else {
      const downloadIframe = `<iframe id="frame-download" title="iframe-download" width="0" height="0" style="display: none" src="${url}/download?client_id=${config.api_key}"/>`;
      iframesContainer.innerHTML = downloadIframe;
    }
  }

  render() {
    const { file, index, inputPlaceholder } = this.props;

    let imgPreviewStyle = null;

    if (file.thumbnail_url)
      imgPreviewStyle = {
        backgroundImage:
          'url("' +
          (file.thumbnail_url.indexOf("?client_id=" + config.api_key) !== -1
            ? file.thumbnail_url + "?client_id=" + config.api_key
            : file.thumbnail_url) +
          '")',
      };
    else
      imgPreviewStyle = {
        backgroundImage:
          'url("/assets/images/medias/file-extension-' +
          file.extension +
          '.png")',
      };

    return (
      <div
        className={"file-preview " + (file.extension ? file.extension : "")}
        key={index}
      >
        <div className="cover-actions">
          <DownloadIcon path={file.url} onClick={this.handleDownloadMedia} />
          <IconButton
            size="small"
            aria-label="Delete"
            className="delete-file file-card-action"
            onClick={this.handleDeleteFile}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </div>
        <div className="img-preview" style={imgPreviewStyle} />
        {file && file.media_type && file.media_type.has_templates && (
          <ApiAutoCompleteComponent
            id={"template-file-" + generateHash(file.url)}
            name={"template-file-" + generateHash(file.url)}
            placeholder={
              inputPlaceholder ? inputPlaceholder + "*" : "Search & select*"
            }
            defaultValue={
              file.media_template &&
              file.media_template.name &&
              file.media_template.id
                ? {
                    name: file.media_template.name,
                    id: file.media_template.id,
                  }
                : null
            }
            dataSourceRoute={"media-types/" + file.media_type.id + "/templates"}
            dataSourceParams={{ sort: "name", limit: 5 }}
            isClearable={true}
            listStyle={{ fontSize: "9px!important" }}
            menuStyle={{ fontSize: "9px!important" }}
            onSelectValue={this.handleSelectValue}
            onDeleteValue={this.handleRemoveValue}
          />
        )}
      </div>
    );
  }
}

FileCard.propTypes = {
  index: PropTypes.any,
  inputPlaceholder: PropTypes.string.isRequired,
  file: PropTypes.shape({
    extension: PropTypes.string,
    thumbnail_url: PropTypes.string,
    media_type: PropTypes.shape(),
    media_template: PropTypes.shape(),
    url: PropTypes.string
  }).isRequired,
  onDeleteFile: PropTypes.func.isRequired,
};

export default FileCard;
