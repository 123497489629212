/* eslint-disable react/jsx-no-bind */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { arrayMove } from "react-sortable-hoc";
import classNames from "classnames";

import CircularProgress from "@material-ui/core/CircularProgress";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import { formatBytes, isStringUrl } from "../../../../helpers/functions/utils";
import { apiPostFile } from "../../../../helpers/functions/api";
import {
  getMediaTypeMedias,
  resetMediaTypeMedias,
  setMediaType,
  setMediaTypeMedia,
  setMediaTypeProperty,
  setOpmInitBuffer,
  setOpmProductId,
  unsetMediaTypeMedia,
  unsetMediaTypes,
} from "../../../../helpers/functions/redux";

import FileUploader from "../../files/FileUploader";

import translation from "../../../../translation/translation";

const setReduxMediaType = (mediaType) => {
  if (!mediaType) return;

  /**
   * Create a media type bucket
   */
  const bucket = {
    id: mediaType.id,
    shortcode: mediaType.shortcode,
    quantity_min: mediaType.quantity_min,
    quantity_max: mediaType.quantity_max,
    medias: mediaType.medias,
    ready:
      mediaType.medias.length > mediaType.quantity_min &&
      mediaType.medias.length < mediaType.quantity_max
        ? true
        : false,
  };

  /**
   * Set the media type bucket into redux store
   */
  setMediaType(bucket);
};

const doesFileMeetRestrictions = (file, restrictions, nbFiles) => {
  if (!file) return;

  let errorMessage = "";

  /**
   * Check the quantity of the file
   */
  if (
    nbFiles &&
    restrictions.quantity_max &&
    restrictions.quantity_max <= nbFiles.length
  ) {
    errorMessage = translation().orders.media_groups.restrictions.overlimit;
    return errorMessage;
  }

  /**
   * Check the type of the file
   */
  if (restrictions.type && restrictions.type === "iframe") {
    errorMessage = translation().orders.media_groups.restrictions.fill_url;
    return errorMessage;
  }

  /**
   * Check the extension of the file
   */
  if (
    restrictions.extensions &&
    restrictions.extensions.length &&
    restrictions.extensions.indexOf(file.extension) < 0
  ) {
    errorMessage = translation().orders.media_groups.restrictions
      .extension_refused;
    return errorMessage;
  }

  /**
   * Check the mime_type of the file
   */
  if (
    restrictions.mime_types &&
    restrictions.mime_types.length &&
    restrictions.mime_types.indexOf(file.mime_type) < 0
  ) {
    errorMessage = translation().orders.media_groups.restrictions
      .file_type_refused;
    return errorMessage;
  }

  /**
   * Check the size of the file
   */
  if (restrictions.size_min && file.size && restrictions.size_min > file.size) {
    errorMessage = translation().orders.media_groups.restrictions.weight_light;
    return errorMessage;
  }
  if (restrictions.size_max && file.size && restrictions.size_max < file.size) {
    errorMessage = translation().orders.media_groups.restrictions.weight_heavy;
    return errorMessage;
  }

  /**
   * Check the dimensions of the file
   */
  if (
    restrictions.width_min &&
    file.width &&
    restrictions.width_min > file.width
  ) {
    errorMessage = translation().orders.media_groups.restrictions.width_small;
    return errorMessage;
  }
  if (
    restrictions.width_max &&
    file.width &&
    restrictions.width_max < file.width
  ) {
    errorMessage = translation().orders.media_groups.restrictions.width_large;
    return errorMessage;
  }
  if (
    restrictions.height_min &&
    file.height &&
    restrictions.height_min > file.height
  ) {
    errorMessage = translation().orders.media_groups.restrictions.height_small;
    return errorMessage;
  }
  if (
    restrictions.height_max &&
    file.height &&
    restrictions.height_max < file.height
  ) {
    errorMessage = translation().orders.media_groups.restrictions.height_large;
    return errorMessage;
  }

  return true;
};

function IframeInput(props) {
  function handleChange(event) {
    const { data, onChange } = props;

    if (onChange && event) onChange(event, data);
  }

  return <TextField {...props} onChange={handleChange} />;
}

IframeInput.propTypes = {
  data: PropTypes.any,
  onChange: PropTypes.func,
};

class MediaGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      acceptedFiles: "*",
      isDownloadLoading: false,
      filesArray: [],
      filesAccepted: [],
      filesRejected: [],
      fileError: null,
      filesUploadedCount: {},
      uploadInProgressCount: 0,
      extensionsAccepted: [],
      fileLimitMaxSum: 0,
      fileLimitMinSum: 0,
      totalMaxSize: 50000000,
      totalMinSize: 0,
    };

    this.handleDrop = this.handleDrop.bind(this);
    this.handleDeleteFile = this.handleDeleteFile.bind(this);
    this.handleCheckIframeUrl = this.handleCheckIframeUrl.bind(this);
    this.handleSortEnd = this.handleSortEnd.bind(this);
    this.handleDownloadMedias = this.handleDownloadMedias.bind(this);
    this.checkIsDownloadable = this.checkIsDownloadable.bind(this);
    this.renderFilesRestrictions = this.renderFilesRestrictions.bind(this);
    this.renderMediaGroupIframe = this.renderMediaGroupIframe.bind(this);
  }

  componentWillMount() {
    const { mediaGroup, productId } = this.props;

    /**
     * Set redux init buffer to false
     */
    setOpmInitBuffer(false);

    /**
     * Set the product id into redux, for send after complete mediaGroup
     */
    setOpmProductId(productId);

    const filesArray = [];

    let filesUploadedCount = {};
    let fileLimitMaxSum = null;
    let fileLimitMinSum = null;
    let totalMaxSize = null;
    let totalMinSize = null;
    let extensionsAccepted = [];

    if (mediaGroup.quantity_min) fileLimitMinSum = mediaGroup.quantity_min;

    if (mediaGroup.quantity_max) fileLimitMaxSum = mediaGroup.quantity_max;

    if (mediaGroup.size_max) totalMaxSize = mediaGroup.size_max;

    if (mediaGroup.size_min) totalMinSize = mediaGroup.size_min;

    if (mediaGroup.extensions && mediaGroup.extensions.length) {
      for (let i = 0; i < mediaGroup.extensions.length; i++) {
        extensionsAccepted.push(`.${mediaGroup.extensions[i]}`);
      }
    }

    if (mediaGroup.mime_types && mediaGroup.mime_types.length) {
      for (let i = 0; i < mediaGroup.mime_types.length; i++) {
        extensionsAccepted.push(mediaGroup.mime_types[i]);
      }
    }

    if (mediaGroup.media_types && mediaGroup.media_types.length) {
      for (let i = 0; i < mediaGroup.media_types.length; i++) {
        /**
         * Store all media type in redux
         */
        setReduxMediaType(mediaGroup.media_types[i]);

        if (mediaGroup.media_types[i].medias) {
          /**
           * Fill in redux all medias already uploaded in the api storage for his media type
           */
          filesUploadedCount[mediaGroup.media_types[i].shortcode] =
            mediaGroup.media_types[i].medias.length;

          for (let j = 0; j < mediaGroup.media_types[i].medias.length; j++) {
            let file = mediaGroup.media_types[i].medias[j];
            file.media_type = mediaGroup.media_types[i];

            delete file.medias;

            if (
              mediaGroup.media_types[i].type !== "iframe" &&
              mediaGroup.media_types[i].type !== "form"
            ) {
              filesArray.push(file);
            }
          }
        }
      }
    }

    /**
     * Set redux init buffer to true, the redux object is ready to be used
     */
    setOpmInitBuffer(true);

    this.setState({
      filesArray,
      fileLimitMaxSum,
      fileLimitMinSum,
      extensionsAccepted,
      totalMaxSize,
      totalMinSize,
      filesUploadedCount,
    });
  }

  componentWillUnmount() {
    unsetMediaTypes();
  }

  hideFileErrorMessage() {
    setTimeout(() => {
      this.setState({ fileError: null });
    }, 3000);
  }

  handleDrop(filesAccepted, filesRejected) {
    this.setState({
      filesAccepted,
      filesRejected,
    });

    const { mediaGroup } = this.props;

    /*
     * Post to api file one by one
     * Get the url of response data then add it to file url array
     */
    if (filesAccepted) {
      for (let i = 0; i < filesAccepted.length; i++) {
        const filesArray = this.state.filesArray;

        /**
         * PRE-UPLOAD CHECKS: check the quantity of files in dropper
         */
        if (
          filesAccepted.length + filesArray.length <=
          this.state.fileLimitMaxSum
        ) {
          if (filesAccepted.length <= this.state.fileLimitMaxSum) {
            if (filesArray.length < this.state.fileLimitMaxSum) {
              this.setState({
                uploadInProgressCount:
                  this.state.uploadInProgressCount + filesAccepted.length,
              });

              apiPostFile(
                filesAccepted[i],
                (success) => {
                  const newFile =
                    success.data && success.data ? success.data : "";

                  /**
                   * POST-UPLOAD CHECKS: extensions, sizes...
                   */
                  if (doesFileMeetRestrictions(newFile, mediaGroup) !== true) {
                    this.setState({
                      fileError: doesFileMeetRestrictions(newFile, mediaGroup),
                      uploadInProgressCount:
                        this.state.uploadInProgressCount - 1,
                    });

                    this.hideFileErrorMessage();
                    return;
                  }

                  let mediaTypeRestrictionsCheckResult = false;

                  /**
                   * Check which media type has been uploaded
                   */
                  for (let j = 0; j < mediaGroup.media_types.length; j++) {
                    mediaTypeRestrictionsCheckResult = doesFileMeetRestrictions(
                      newFile,
                      mediaGroup.media_types[j],
                      getMediaTypeMedias(mediaGroup.media_types[j].shortcode)
                    );

                    if (mediaTypeRestrictionsCheckResult === true) {
                      newFile.media_type = mediaGroup.media_types[j];

                      const filesUploadedCount = this.state.filesUploadedCount;
                      filesUploadedCount[
                        mediaGroup.media_types[j].shortcode
                      ] += 1;

                      /**
                       * Fill in redux bucket the new file uploaded in his mediatype
                       * Init position of medias
                       */
                      newFile.position = this.state.filesArray.length;

                      if (newFile.media_type.has_templates)
                        newFile.media_template_id = "null";

                      setMediaTypeMedia(
                        newFile,
                        mediaGroup.media_types[j].shortcode
                      );

                      this.setState({
                        filesUploadedCount: filesUploadedCount,
                        filesArray: [...this.state.filesArray, newFile],
                        uploadInProgressCount:
                          this.state.uploadInProgressCount - 1,
                      });

                      return true;
                    }
                  }

                  /**
                   * Error: decrement the nb of file not uploaded
                   * Display local error message
                   */
                  this.setState({
                    fileError: mediaTypeRestrictionsCheckResult,
                    uploadInProgressCount: this.state.uploadInProgressCount - 1,
                  });

                  this.hideFileErrorMessage();
                },
                (error) => {
                  if (
                    error.response &&
                    error.response.data &&
                    error.response.data.detail
                  ) {
                    this.setState({
                      fileError: error.response.data.detail,
                      uploadInProgressCount:
                        this.state.uploadInProgressCount - 1,
                    });
                    this.hideFileErrorMessage();
                  } else {
                    this.setState({
                      fileError: "Unknown error. Please retry later.",
                      uploadInProgressCount:
                        this.state.uploadInProgressCount - 1,
                    });
                  }
                }
              );
            } else {
              this.setState({
                fileError:
                  translation().orders.media_groups.file_limit +
                  this.state.fileLimitMaxSum,
              });
              this.hideFileErrorMessage();
              return;
            }
          } else {
            this.setState({
              fileError:
                translation().orders.media_groups.file_limit +
                this.state.fileLimitMaxSum,
            });
            this.hideFileErrorMessage();
            return;
          }
        } else {
          this.setState({
            fileError:
              translation().orders.media_groups.file_limit +
              this.state.fileLimitMaxSum,
          });
          this.hideFileErrorMessage();
          return;
        }
      }
    }

    /*
     * Handling files rejected
     */
    if (filesRejected && filesRejected.length > 0) {
      let fileError = translation().orders.media_groups.files;

      for (let j = 0; j < filesRejected.length; j++) {
        if (filesRejected[j].name) fileError += filesRejected[j].name + ", ";
      }

      fileError += translation().orders.media_groups.refused;

      this.setState({ fileError });
      this.hideFileErrorMessage();
    }
  }

  handleSortEnd({ oldIndex, newIndex }) {
    /**
     * Sort fileCards position
     */
    this.setState({
      filesArray: arrayMove(this.state.filesArray, oldIndex, newIndex),
    });

    if (oldIndex === newIndex) return;

    /**
     * Reset the position of the file concerned in redux bucket
     */
    for (let i = 0; i < this.state.filesArray.length; i++)
      setMediaTypeProperty(
        this.state.filesArray[i].url,
        this.state.filesArray[i].media_type.shortcode,
        "position",
        i + 1
      );
  }

  handleCheckIframeUrl(event, mediaType) {
    if (!mediaType.shortcode)
      console.error(
        "Error on iframe checking function: missing mediaType shortcode."
      );

    const input = document.getElementById(
      "media-input-wrapper-" + mediaType.shortcode
    );

    const successCallback = (event, url) => {
      try {
        const newFilesUploadedCount = this.state.filesUploadedCount;
        newFilesUploadedCount[mediaType.shortcode] = 1;

        if (input) {
          input.classList.remove("success");
          input.classList.remove("error");
          input.classList.add("success");
        }

        /**
         * The iframe is valid
         */
        this.setState({
          filesUploadedCount: newFilesUploadedCount,
          isUrlRequestInProgress: false,
        });

        /**
         * Fill in redux the new iframe file in his mediatype
         */
        setMediaTypeMedia(
          { url: url, media_type: mediaType },
          mediaType.shortcode
        );
      } catch (e) {
        console.error(e);
      }
    };

    const failCallback = (/*event*/) => {
      const newFilesUploadedCount = this.state.filesUploadedCount;
      newFilesUploadedCount[mediaType.shortcode] = 0;

      if (input) {
        input.classList.remove("success");
        input.classList.add("error");
      }

      this.setState({
        filesUploadedCount: newFilesUploadedCount,
        isUrlRequestInProgress: false,
      });

      /**
       * Delete in redux the new iframe file in his mediatype
       */
      resetMediaTypeMedias(mediaType.shortcode);
    };

    /**
     * Delete in redux the iframe media if she already exists in api response
     */
    resetMediaTypeMedias(mediaType.shortcode);

    if (input) input.classList.remove("success");

    if (!mediaType) return;

    if (event && event.target && event.target.value) {
      event.persist();
      const url = event.target.value;

      this.setState({ isUrlRequestInProgress: true });

      if (isStringUrl(url)) {
        /**
         * The url is valid
         */
        successCallback(event, url);
      } else {
        /**
         * The url is not valid
         */
        failCallback(event);
      }
    } else {
      /**
       * No value in input
       */
      failCallback(event);
    }
  }

  handleDeleteFile(file) {
    if (!file.url) return;

    const { filesArray } = this.state;

    let shortcode = null;

    const newFilesArray = filesArray.filter((el) => {
      if (el.url === file.url) {
        shortcode = el.media_type.shortcode;
        return false;
      }
      return true;
    });

    let filesUploadedCount = this.state.filesUploadedCount;
    filesUploadedCount[shortcode] -= 1;

    this.setState({
      filesArray: newFilesArray,
      filesUploadedCount: filesUploadedCount,
    });

    /**
     * Target and delete the file in redux bucket
     */
    unsetMediaTypeMedia(file.url, shortcode);
  }

  handleDownloadMedias() {
    const {
      downloadOrderProductMedias,
      mediaGroup,
      productId,
      orderId,
    } = this.props;

    const { isDownloadLoading } = this.state;

    if (!orderId || isDownloadLoading) return;

    this.setState({ isDownloadLoading: true });

    const downloadRequest = {
      media_group_id: mediaGroup.id,
    };

    downloadOrderProductMedias(
      orderId,
      productId,
      downloadRequest,
      () => {
        this.setState({ isDownloadLoading: false });
      },
      () => {
        this.setState({ isDownloadLoading: false });
      }
    );
  }

  checkIsDownloadable() {
    const { mediaGroup } = this.props;

    let isDownloadable = false;

    /**
     * Check of all media groups and medias types if the media groups need a downloader
     */
    if (mediaGroup.media_types && mediaGroup.media_types.length) {
      for (let j = 0; j < mediaGroup.media_types.length; j++) {
        if (
          mediaGroup.media_types[j].type &&
          mediaGroup.media_types[j].type === "iframe"
        ) {
          if (
            mediaGroup.media_types[j].medias &&
            mediaGroup.media_types[j].medias.length
          ) {
            for (let k = 0; k < mediaGroup.media_types[j].medias.length; k++) {
              if (mediaGroup.media_types[j].medias[k].thumbnail_url) {
                isDownloadable = true;
                break;
              } else if (mediaGroup.media_types[j].medias[k].extension) {
                isDownloadable = true;
                break;
              }
            }
          }
        } else if (
          mediaGroup.media_types[j].type &&
          mediaGroup.media_types[j].type !== "iframe"
        ) {
          if (
            mediaGroup.media_types[j].medias &&
            mediaGroup.media_types[j].medias.length
          ) {
            isDownloadable = true;
            break;
          }
        }
      }
    }

    return isDownloadable;
  }

  checkIfMediaGroupHasForm(mediaGroup) {
    if (!mediaGroup) return false;

    let hasForm = false;

    if (mediaGroup.media_types && mediaGroup.media_types.length > 0) {
      for (let i = 0; i < mediaGroup.media_types.length; i++) {
        const mediaType = mediaGroup.media_types[i];

        if (mediaType.type && mediaType.type === "form") {
          hasForm = true;
          break;
        }
      }
    }

    return hasForm;
  }

  renderFilesRestrictions(mediaGroup) {
    const { filesUploadedCount } = this.state;

    if (!mediaGroup) return;

    let filesRestrictions = [];

    if (mediaGroup.media_types && mediaGroup.media_types.length > 0) {
      for (let i = 0; i < mediaGroup.media_types.length; i++) {
        let mediaType = mediaGroup.media_types[i];

        /**
         * Add class upload status to nb file counter
         */
        let filesUploadStatus =
          filesUploadedCount[mediaType.shortcode] > 0 ? "pending" : "";

        if (filesUploadedCount[mediaType.shortcode] >= mediaType.quantity_min)
          filesUploadStatus = "done";

        filesRestrictions.push(
          <div className="files-infos" key={i}>
            <div className="nb-files">
              <span className={"counter " + filesUploadStatus}>
                {filesUploadedCount[mediaType.shortcode]}/
                {mediaType.quantity_min && mediaType.quantity_min}
              </span>
            </div>
            <div className="files-details">
              <span
                className={
                  "file-img icon-file-type-" +
                  (mediaType.type ? mediaType.type : "")
                }
              />
              <div className="restrictions">
                {mediaType.name && (
                  <p className="name">
                    <span>{mediaType.name}</span>
                  </p>
                )}
                {mediaType.extensions && mediaType.extensions.length ? (
                  <p>
                    <span>
                      {translation().orders.media_groups.restrictions.type} [
                      {mediaType.extensions.join(", ")}]
                    </span>
                  </p>
                ) : (
                  ""
                )}
                {mediaType.size_min || mediaType.size_max ? (
                  <p>
                    <span>
                      {translation().orders.media_groups.restrictions.weight} [
                      {mediaType.size_min
                        ? formatBytes(mediaType.size_min, 3)
                        : "0 " +
                          translation().orders.media_groups.restrictions
                            .bytes}{" "}
                      -{" "}
                      {mediaType.size_max
                        ? formatBytes(mediaType.size_max, 0)
                        : "?"}
                      ]
                    </span>
                  </p>
                ) : (
                  ""
                )}
                {mediaType.width_min || mediaType.width_max ? (
                  <p>
                    <span>
                      {translation().orders.media_groups.restrictions.width} [
                      {mediaType.width_min ? mediaType.width_min : "0"}px -{" "}
                      {mediaType.width_max ? mediaType.width_max : "?"}px]
                    </span>
                  </p>
                ) : (
                  ""
                )}
                {mediaType.height_min || mediaType.height_max ? (
                  <p>
                    <span>
                      {translation().orders.media_groups.restrictions.height} [
                      {mediaType.height_min ? mediaType.height_min : "0"}px -{" "}
                      {mediaType.height_max ? mediaType.height_max : "?"}px]
                    </span>
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        );
      }
    }

    return filesRestrictions;
  }

  renderMediaGroupIframe(mediaGroup) {
    if (!mediaGroup) return;

    let iframes = [];

    if (mediaGroup.media_types && mediaGroup.media_types.length > 0) {
      for (let i = 0; i < mediaGroup.media_types.length; i++) {
        if (
          mediaGroup.media_types[i].type &&
          mediaGroup.media_types[i].type === "iframe"
        ) {
          iframes.push(
            <div
              key={i}
              className={
                "media-input-wrapper " +
                (mediaGroup.media_types[i].medias &&
                mediaGroup.media_types[i].medias.length &&
                mediaGroup.media_types[i].medias[0].url
                  ? "success"
                  : "")
              }
              id={
                "media-input-wrapper-" +
                (mediaGroup.media_types[i].shortcode
                  ? mediaGroup.media_types[i].shortcode
                  : "")
              }
            >
              <IframeInput
                type="text"
                data={mediaGroup.media_types[i]}
                label={translation().orders.media_groups.media_link}
                name={
                  "input-iframe-" +
                  (mediaGroup.media_types[i].shortcode
                    ? mediaGroup.media_types[i].shortcode
                    : "")
                }
                defaultValue={
                  mediaGroup.media_types[i].medias &&
                  mediaGroup.media_types[i].medias[0] &&
                  mediaGroup.media_types[i].medias[0].url
                    ? mediaGroup.media_types[i].medias[0].url
                    : ""
                }
                InputLabelProps={{
                  style: { color: "rgb(115, 115, 115)" },
                }}
                inputProps={{
                  style: { color: "#FFFFFF" },
                }}
                autoComplete="nope"
                onChange={this.handleCheckIframeUrl}
                className="media-input"
                margin="normal"
                variant="outlined"
                fullWidth={true}
              />
            </div>
          );
        }
      }
    }

    return iframes;
  }

  render() {
    const { mediaGroup } = this.props;

    const {
      isDownloadLoading,
      fileError,
      filesArray,
      uploadInProgressCount,
      extensionsAccepted,
      totalMaxSize,
      totalMinSize,
    } = this.state;

    /**
     * Get which mediagroup need an uploader
     */
    let needsUploader = false;

    if (mediaGroup && mediaGroup.media_types) {
      for (let i = 0; i < mediaGroup.media_types.length; i++) {
        if (
          ["image", "file", "data", "video"].indexOf(
            mediaGroup.media_types[i].type
          ) >= 0
        ) {
          needsUploader = true;
          break;
        }
      }
    }

    if (this.checkIfMediaGroupHasForm(mediaGroup)) {
      return false;
    } else {
      return (
        <div
          className="media-group"
          style={{ paddingLeft: 15, paddingRight: 15 }}
        >
          {mediaGroup.name && <p className="name">{mediaGroup.name}</p>}
          {this.renderFilesRestrictions(mediaGroup)}
          <div className="clearfix" />
          {fileError && (
            <div className="file-error">
              <p className="error-message">{fileError}</p>
            </div>
          )}
          {this.checkIsDownloadable() && (
            <div
              className={classNames("download-all-medias", {
                disabled: isDownloadLoading,
              })}
              onClick={this.handleDownloadMedias}
            >
              <CloudDownloadIcon fontSize="small" />{" "}
              <Typography component="span" className="text-dl-medias">
                {translation().commons.download}
              </Typography>
              {isDownloadLoading && (
                <CircularProgress size={15} color="secondary" />
              )}
            </div>
          )}
          {needsUploader && (
            <FileUploader
              onDeleteFile={this.handleDeleteFile}
              extensionsAccepted={extensionsAccepted.join(",")}
              filesArray={filesArray}
              onDrop={this.handleDrop}
              onSortEnd={this.handleSortEnd}
              uploadInProgressCount={uploadInProgressCount}
              totalMinSize={totalMinSize}
              totalMaxSize={totalMaxSize}
              name={
                mediaGroup.name
                  ? mediaGroup.name + "_uploader"
                  : "media-group-uploader"
              }
            />
          )}
          {needsUploader && (
            <p className="required-field-uploader">
              *{translation().forms.errors.required}
            </p>
          )}
          {this.renderMediaGroupIframe(mediaGroup)}
          <div className="product-separator" style={{ margin: "15px 0" }}>
            <div className="first-sep" />
            <div className="second-sep" />
          </div>
        </div>
      );
    }
  }
}

MediaGroup.propTypes = {
  downloadOrderProductMedias: PropTypes.func.isRequired,
  mediaGroup: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.any.isRequired,
    quantity_min: PropTypes.any,
    quantity_max: PropTypes.any,
    size_max: PropTypes.any,
    size_min: PropTypes.any,
    mime_types: PropTypes.any,
    extensions: PropTypes.any,
    media_types: PropTypes.any,
  }),
  orderId: PropTypes.any,
  productId: PropTypes.any.isRequired,
};

export default MediaGroup;
