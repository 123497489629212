const initialState = {
  orderOpen: null,
  list: [],
  loading: false,
  paging: null,
  error: null,
  mediaGroups: {
    list: [],
    isLoading: false,
    error: null
  },
  upload_action: {
    isLoading: false,
    error: null
  },
};

export default (state = initialState, action) => {

  switch (action.type) {
    case 'CLEAR_REDUCERS':
      return initialState;
    case 'OPEN_ORDER':
      return {
        ...state,
        orderOpen: action.id
      }
    case 'CLOSE_ORDER':
      return {
        ...state,
        orderOpen: null
      }
    case 'GET_ORDERS': {
      const ordersList = action.next ? state.list : [];

      return {
        ...state,
        list: ordersList,
        loading: true
      };
    }
    case 'GET_ORDERS_SUCCESS':
      return {
        ...state,
        list: action.data,
        paging: action.paging,
        error: null,
        loading: false
      };
    case 'GET_ORDERS_FAILURE':
      return {
        ...state,
        error: action.error,
        loading: false
      };
    case 'UPDATE_ORDERS': {
      const updatedList = state.list.concat(action.data);

      return {
        ...state,
        list: updatedList,
        paging: action.paging ? action.paging : state.paging,
        loading: false,
        error: null
      }
    }
    case 'CLEAR_MEDIA_GROUPS':
      return {
        ...state,
        mediaGroups: initialState.mediaGroups
      };
    case 'GET_ORDER_PRODUCT_MEDIA_GROUPS':
      return {
        ...state,
        mediaGroups: {
          list: [],
          isLoading: true,
          error: null
        }
      };
    case 'GET_ORDER_PRODUCT_MEDIA_GROUPS_SUCCESS':
      return {
        ...state,
        mediaGroups: {
          list: action.data,
          isLoading: false,
          error: null
        }
      };
    case 'GET_ORDER_PRODUCT_MEDIA_GROUPS_FAILURE':
      return {
        ...state,
        mediaGroups: {
          ...state.mediaGroups,
          isLoading: false,
          error: null
        }
      };
    case 'POST_ORDER_PRODUCT_MEDIAS':
      return {
        ...state,
        upload_action: {
          isLoading: true,
          error: null
        }
      }
    case 'POST_ORDER_PRODUCT_MEDIAS_SUCCESS':
      return {
        ...state,
        upload_action: {
          isLoading: false,
          error: null
        }
      }
    case 'POST_ORDER_PRODUCT_MEDIAS_FAILURE':
      return {
        ...state,
        upload_action: {
          isLoading: false,
          error: action.error
        }
      }
    default:
      return state;
  }
};
