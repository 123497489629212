import $ from 'jquery';

/**
 * Remove element from DOM
 */
export const removeElement = (element) => {
  $(element).remove();
}

/**
 * Animated scroll auto top when switching shop steps
 */
export const autoScrollTop = () => {
  $('html,body').animate( {scrollTop: 0}, 800);
}

/**
 * Animated scroll to an anchor
 */
export const scrollToAnchor = (anchor) => {
  $('html, body').animate({
    'scrollTop': $(anchor).offset().top - 80
  }, 1000);
}

/**
 * Detect scroll to bottom of page
 */
export const handleInfiniteScroll = (className = null, additionalHeight = 50) => {

  if (className) {
    const $scrollContainer = $(className);

    if ($scrollContainer) {
      if (($scrollContainer.scrollTop() + $scrollContainer.innerHeight()) + additionalHeight >= $scrollContainer[0].scrollHeight)
        return true;
      else
        return false
    }
    else
      return false
  }
  else {
    if (($(window).scrollTop() + $(window).height()) + additionalHeight > $(document).height())
      return true;
    else
      return false;
  }
}

/**
 * Calc and set width and position of order cards
 * console.log('%c Ref jquery order wrapper triggered.', "color:#FFC107");
 */
export function adjustOrderCard() {
  const wrapperWidth = $('.orders-wrapper').width();

  $('.order-wrapper').each(function() {
    const offset = $(this).position().left;
    const sidePadding = 0;
    $(this).width(wrapperWidth);
    $(this).css('margin-left', '-' + (offset + sidePadding) + 'px');
  });
}

/**
 * Animated scroll on open account order
 */
export const orderContentScroll = id => {

  if (!id)
    return;

  const speed = 800;
  const headerHeight = 130;
  const orderCardHeight = $('.order-cover').height();
  const $domPage = $('html, body');
  const $elem = $('#order-' + id);
  const $target = $elem.offset().top -= (headerHeight - orderCardHeight);

  $domPage.on("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove", function() {
     $domPage.stop();
  });
  $domPage.animate( { scrollTop: $target }, speed, function() {
     $domPage.off("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove");
  });

  return false;
}

$(document).ready(function() {

  /**
   * Init smooth scroll
   */
  const speed = 750;
  const domPage = $('html, body');

  $('.scroll-anch').on('click', function() {
    const page = $(this).attr('href');
    const target = $(page).offset().top -= 100;

    domPage.on("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove", function() {
       domPage.stop();
    });
    domPage.animate( { scrollTop: target }, speed, function() {
       window.location.hash = page;
       domPage.off("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove");
    });
    return false;
  });

  /**
   * Add scroll to top button when user is on bottom page
   */
  $(window).scroll(function() {
    if( $(window).scrollTop() + $(window).height() === $(document).height() ) {
     $('#scroll-to-top').fadeIn();
    }
    else {
      $('#scroll-to-top').fadeOut();
    }
  });
  $('#scroll-to-top').on('click', function() {
    domPage.animate( { scrollTop: 0 }, speed, function() {
       domPage.off("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove");
    });
    return false;
  });
});
