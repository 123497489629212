import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";
import Typography from "@material-ui/core/Typography";

function Alert(props) {
  const { className, icon, text, type, status, style } = props;

  function renderIcon() {
    switch (type) {
      case "danger":
        return <ErrorIcon className="alert-icon" />;
      case "warning":
        return <WarningIcon className="alert-icon" />;
      case "info":
        return <InfoIcon className="alert-icon" />;
      default:
        return <InfoIcon className="alert-icon" />;
    }
  }

  return (
    <blockquote
      style={style}
      className={classNames(className, `alert alert-${type}`, {
        "wrap-alert": status,
      })}
    >
      {icon ? icon : renderIcon()}
      {status ? (
        <Typography component="span" className="alert-text alert-status">
          {status}
        </Typography>
      ) : (
        false
      )}
      <Typography component="span" className="alert-text">
        {text}
      </Typography>
    </blockquote>
  );
}

Alert.defaultProps = {
  type: "info",
};

Alert.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node,
  style: PropTypes.shape(),
  status: PropTypes.string,
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["info", "danger", "warning"]),
};

export default Alert;
