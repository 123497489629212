import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Flag } from "@material-ui/icons";

const styles = () => ({
  1: {
    color: "#b8c4d2",
  },
  2: {
    color: "#ffce42",
  },
  3: {
    color: "#ff4842",
  },
});

function PriorityFlag(props) {
  const { classes, value } = props;

  return <Flag className={classes[value]} />;
}

PriorityFlag.propTypes = {
  classes: PropTypes.shape(),
  value: PropTypes.number,
};

export default withStyles(styles)(PriorityFlag);
