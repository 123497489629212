/* eslint-disable react/jsx-no-bind */
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { IconButton, Paper, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { CloudDownload, Delete } from "@material-ui/icons";

import config from "../../../config";

const styles = () => ({
  head: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    width: "100%",
    top: 0,
    left: 0,
    zIndex: 1,
    padding: 4,
  },
  grow: {
    flexGrow: 1,
  },
  name: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    fontSize: 10,
    padding: 4,
    backgroundColor: "rgb(0, 0, 0, 0.4)",
    color: "white",
  },
  paper: {
    padding: 0,
    overflow: "hidden",
  },
});

function DropCard(props) {
  const { disabled, classes, file, onDeleteFile, showFilesName } = props;

  const typesImage = [
    "jpg",
    "jpeg",
    "JPG",
    "JPEG",
    "pjp",
    "pjpeg",
    "jfif",
    "png",
    "PNG",
    "gif",
    "GIF",
    "webp",
    "tif",
    "tiff",
    "bmp",
    "svg",
  ];
  let imgPreviewStyle = {};

  if (file.thumbnail_url) {
    imgPreviewStyle = {
      backgroundImage:
        'url("' +
        (file.thumbnail_url.indexOf(`client_id=${config.api_key}`) !== -1
          ? file.thumbnail_url
          : `${file.thumbnail_url}?client_id=${config.api_key}`) +
        '")',
    };
  } else if (typesImage.indexOf(file.extension) >= 0 && file.url) {
    imgPreviewStyle = {
      backgroundImage:
        'url("' +
        (file.url.indexOf(`client_id=${config.api_key}`) !== -1
          ? file.url
          : `${file.url}?client_id=${config.api_key}`) +
        '")',
    };
  } else {
    imgPreviewStyle = {
      backgroundSize: "inherit",
      backgroundImage: `url("/assets/images/medias/file-extension-${file.extension}.png")`,
    };
  }

  function handleDelete() {
    if (file) {
      onDeleteFile(file);
    }
  }

  function handleDownload() {
    if (!file.url) return;

    const downloadIframe = document.getElementById("frame-download");
    const iframesContainer = document.getElementById("iframes-container");

    /**
     * If iframe already exists we only change url, if not we create it
     */
    if (downloadIframe) {
      downloadIframe.setAttribute(
        "src",
        `${file.url}/download?client_id=${config.api_key}`
      );
    } else {
      const downloadIframe = `<iframe id="frame-download" title="iframe-download" width="0" height="0" style="display: none" src="${file.url}/download?client_id=${config.api_key}"/>`;
      iframesContainer.innerHTML = downloadIframe;
    }
  }

  return (
    <Paper
      className={classNames(classes.paper, "file-preview", {
        [`${file.extension}`]: file.extension,
      })}
    >
      <div className={classes.head}>
        <IconButton
          size="small"
          onClick={handleDownload}
          disabled={disabled}
          className="media-action"
        >
          <CloudDownload className="media-action-icon" />
        </IconButton>
        <div className={classes.grow} />
        <IconButton
          size="small"
          onClick={handleDelete}
          disabled={disabled}
          className="media-action delete-action"
        >
          <Delete className="media-action-icon" />
        </IconButton>
      </div>
      <div className="img-preview" style={imgPreviewStyle}>
        {showFilesName && file.name && (
          <Typography className={classes.name}>{file.name}</Typography>
        )}
      </div>
    </Paper>
  );
}

DropCard.defaultProps = {
  disabled: false,
  showFilesName: false,
};

DropCard.propTypes = {
  disabled: PropTypes.bool,
  classes: PropTypes.shape().isRequired,
  file: PropTypes.shape({
    extension: PropTypes.string,
    name: PropTypes.string,
    thumbnail_url: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  onDeleteFile: PropTypes.func.isRequired,
  showFilesName: PropTypes.bool,
};

export default withStyles(styles)(DropCard);
