import translation from '../../../../translation/translation';

const validate = values => {

  const errors = {};

  if (!values.old_password)
    errors.old_password = translation().forms.errors.required;

  if (!values.new_password)
    errors.new_password = translation().forms.errors.required;

  if (values.new_password && values.new_password.length < 8)
    errors.new_password = translation().forms.errors.password_length;

  if (!values.confirmPwd)
    errors.confirmPwd = translation().forms.errors.required;

  if (values.new_password && values.confirmPwd) {
    if (values.new_password !== values.confirmPwd)
      errors.confirmPwd = translation().forms.errors.password_not_matching;
  }

  return errors;
}

export default validate;
