/* eslint-disable react/jsx-no-bind */
// import "./styles.scss";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Alert from "../../alert/Alert";
import Dropper from "./Dropper";
import { apiPostFile } from "../../../helpers/functions/api";
import translation from "../../../translation/translation";

function DropContainer(props) {
  const {
    accept,
    defaultFiles,
    disabled,
    maxFiles,
    onChange,
    onDrop,
    showFilesName,
    type,
  } = props;

  let acceptedExtensions = accept;

  if (type === "image") {
    acceptedExtensions =
      "image/jpeg, image/png, image/gif, image/bmp, image/webp, image/tiff, image/svg+xml";
  } else if (type === "file") {
    acceptedExtensions =
      "application/pdf, text/csv, text/plain, text/xml, application/zip, application/x-zip-compressed, application/xml, application/xhtml+xml, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.oasis.opendocument.text, video/mpeg, audio/x-mpeg-3, audio/mpeg3, audio/mp3, video/mp4, image/jpeg, image/png, image/gif, image/bmp, image/webp, image/tiff, image/svg+xml";
  }

  const [state, setState] = useState({
    filesAcceptedLength: 0,
    filesArray: defaultFiles,
    fileError: null,
    filesUploadedCount: 0,
    uploadInProgressCount: 0,
    maxSize: 10000000,
    minSize: 0,
  });

  function hideFileErrorMessage() {
    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        fileError: null,
      }));
    }, 3000);
  }

  useEffect(() => {
    if (onChange) {
      onChange(state.filesArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.filesArray]);

  function handleDrop(filesAccepted, filesRejected) {
    if (onDrop) {
      onDrop(filesAccepted, filesRejected);
    }

    const filesArray = [...state.filesArray];

    /**
     * Post to api file one by one
     * Get the url of response data then add it to file url array
     */
    if (filesAccepted && filesAccepted.length > 0) {
      setState((prevState) => ({
        ...prevState,
        filesAcceptedLength:
          prevState.filesAcceptedLength + filesAccepted.length,
        uploadInProgressCount:
          prevState.uploadInProgressCount + filesAccepted.length,
      }));

      for (let i = 0; i < filesAccepted.length; i++) {
        /**
         * PRE-UPLOAD CHECKS: check the quantity of files in dropper
         */
        if (state.filesAcceptedLength + filesAccepted.length <= maxFiles) {
          if (filesAccepted.length + filesArray.length <= maxFiles) {
            if (filesAccepted.length <= maxFiles) {
              if (filesArray.length < maxFiles) {
                apiPostFile(
                  filesAccepted[i],
                  (success) => {
                    const newFile = success && success.data ? success.data : "";

                    setState((prevState) => ({
                      ...prevState,
                      filesArray: [...prevState.filesArray, newFile],
                      uploadInProgressCount:
                        prevState.uploadInProgressCount - 1,
                    }));
                  },
                  (error) => {
                    setState((prevState) => ({
                      ...prevState,
                      fileError:
                        error &&
                        error.response &&
                        error.response.data &&
                        error.response.data.detail
                          ? error.response.data.detail
                          : "Unknow error",
                      filesAcceptedLength: prevState.filesAcceptedLength - 1,
                      uploadInProgressCount:
                        prevState.uploadInProgressCount - 1,
                    }));

                    hideFileErrorMessage();
                  }
                );
              } else {
                setState((prevState) => ({
                  ...prevState,
                  fileError:
                    translation().orders.media_groups.file_limit + maxFiles,
                  filesAcceptedLength:
                    prevState.filesAcceptedLength - filesAccepted.length,
                  uploadInProgressCount:
                    prevState.uploadInProgressCount - filesAccepted.length,
                }));

                hideFileErrorMessage();
                return;
              }
            } else {
              setState((prevState) => ({
                ...prevState,
                fileError:
                  translation().orders.media_groups.file_limit + maxFiles,
                filesAcceptedLength:
                  prevState.filesAcceptedLength - filesAccepted.length,
                uploadInProgressCount:
                  prevState.uploadInProgressCount - filesAccepted.length,
              }));

              hideFileErrorMessage();
              return;
            }
          } else {
            setState((prevState) => ({
              ...prevState,
              fileError:
                translation().orders.media_groups.file_limit + maxFiles,
              filesAcceptedLength:
                prevState.filesAcceptedLength - filesAccepted.length,
              uploadInProgressCount:
                prevState.uploadInProgressCount - filesAccepted.length,
            }));

            hideFileErrorMessage();
            return;
          }
        } else {
          setState((prevState) => ({
            ...prevState,
            fileError: translation().orders.media_groups.file_limit + maxFiles,
            filesAcceptedLength:
              prevState.filesAcceptedLength - filesAccepted.length,
            uploadInProgressCount:
              prevState.uploadInProgressCount - filesAccepted.length,
          }));

          hideFileErrorMessage();
          return;
        }
      }
    }

    /**
     * Handling files rejected
     */
    if (filesRejected && filesRejected.length > 0) {
      let fileError = translation().orders.media_groups.files;

      for (let j = 0; j < filesRejected.length; j++) {
        if (filesRejected[j].file && filesRejected[j].file.name) {
          fileError += filesRejected[j].file.name + ", ";
        }
      }

      fileError += translation().orders.media_groups.refused;

      setState((prevState) => ({
        ...prevState,
        fileError,
      }));

      hideFileErrorMessage();
    }
  }

  function handleDeleteFile(file) {
    if (!file.url) return;

    const newFilesArray = state.filesArray.filter((el) => {
      if (el.url === file.url) {
        return false;
      }
      return true;
    });

    setState((prevState) => ({
      ...prevState,
      filesArray: newFilesArray,
      filesUploadedCount: prevState.filesUploadedCount - 1,
      filesAcceptedLength: prevState.filesAcceptedLength - 1,
    }));
  }

  function handleClearDropper() {
    setState((prevState) => ({
      ...prevState,
      filesAcceptedLength: 0,
      filesArray: [],
    }));
  }

  return (
    <div className="MediasDropper minimal-dropper">
      <Dropper
        accept={acceptedExtensions}
        disabled={disabled}
        filesArray={state.filesArray}
        maxFiles={maxFiles}
        maxSize={state.maxSize}
        minSize={state.minSize}
        onClearDroppper={handleClearDropper}
        onDeleteFile={handleDeleteFile}
        onDrop={handleDrop}
        showFilesName={showFilesName}
        uploadInProgressCount={state.uploadInProgressCount}
      />
      {state.fileError && <Alert type="danger" text={state.fileError} />}
    </div>
  );
}

DropContainer.defaultProps = {
  accept: "",
  defaultFiles: [],
  disabled: false,
  maxFiles: 10000,
  showFilesName: false,
  type: "file",
};

DropContainer.propTypes = {
  accept: PropTypes.string,
  defaultFiles: PropTypes.arrayOf(PropTypes.shape()),
  disabled: PropTypes.bool,
  maxFiles: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onDrop: PropTypes.func,
  showFilesName: PropTypes.bool,
  type: PropTypes.string,
};

export default DropContainer;
