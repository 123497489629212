const initialState = {};

/**
 * OrderProductMedias ready status
 */
export const orderProductMediasStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'STORE_OPM_INIT_BUFFER':
      return {
        ...state,
        status: action.status
      }
    case 'STORE_OPM_PRODUCT_ID':
      return {
        ...state,
        product_id: action.id
      }
    case 'REMOVE_OPM_INIT_BUFFER':
      return initialState
    default:
      return state
  }
}

/**
 * OrderProductMedias reducer
 */
export const orderProductMediasReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'STORE_MEDIA_TYPE':
      return {
        ...state,
        [action.mediaType.shortcode]: action.mediaType
      }
    case 'STORE_MEDIA_TYPE_MEDIA': {
      action.media.media_type_id = action.media.media_type.id;

      if (action.media.media_type.medias)
        delete action.media.media_type.medias;

      state[action.mediaTypeShortcode]['medias'].push(action.media);

      return {
        ...state,
        [action.mediaTypeShortcode]: state[action.mediaTypeShortcode]
      }
    }
    case 'STORE_MEDIA_TYPE_MEDIA_PROPERTY': {

      const newMedias1 = state[action.mediaTypeShortcode]['medias'].filter((el) => {
        if (el.url === action.mediaUrl) {
          el[action.mediaProperty] = action.mediaPropertyValue;
        }
        return true;
      });

      state[action.mediaTypeShortcode]['medias'] = newMedias1;

      return {
        ...state,
        [action.mediaTypeShortcode]: state[action.mediaTypeShortcode]
      }
    }
    case 'REMOVE_MEDIA_TYPE_MEDIA': {

      const newMedias2 = state[action.mediaTypeShortcode]['medias'].filter((el) => {
        return (el.url !== action.mediaUrl)
      });

      state[action.mediaTypeShortcode]['medias'] = newMedias2;

      return {
        ...state,
        [action.mediaTypeShortcode]: state[action.mediaTypeShortcode]
      }
    }
    case 'REMOVE_MEDIA_TYPE_MEDIAS': {
      state[action.mediaTypeShortcode]['medias'] = [];

      return {
        ...state,
        [action.mediaTypeShortcode]: state[action.mediaTypeShortcode]
      }
    }
    case 'REMOVE_MEDIA_TYPES':
      return initialState
    default:
      return state
  }
}
