import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CircularProgress from '@material-ui/core/CircularProgress';

import { handleInfiniteScroll } from '../../helpers/functions/jquery';

import SearchForm from './forms/search/SearchForm';
import OrderCard from './order/OrderCard';

import translation from '../../translation/translation';

class Orders extends Component {

  constructor(props) {
    super(props);

    this.handleCloseOrder = this.handleCloseOrder.bind(this);
    this.handleOpenOrder = this.handleOpenOrder.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleSearchOrders = this.handleSearchOrders.bind(this);
    this.renderOrders = this.renderOrders.bind(this);
  }

  componentWillMount() {

    const {
      getOrders,
      user
    } = this.props;

    getOrders(null, user);

    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    const {
      clearMediaGroups
    } = this.props;

    clearMediaGroups();
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {

    const {
      getOrders,
      orders: {
        orderOpen,
        paging,
        loading,
      },
      user
    } = this.props;

    if (handleInfiniteScroll(null, 200)) {
      if (paging && paging.next) {
        if (!loading && !orderOpen)
          getOrders(paging.next, user);
      }
    }
  }

  handleSearchOrders(values) {

    const {
      searchOrders,
      orders: {
        loading,
      },
      user
    } = this.props;

    if (!loading) {
      searchOrders(values && values.search ? values.search : '', user);
    }
  }

  handleOpenOrder(id) {

    const {
      orders: {
        loading
      },
      openOrder
    } = this.props;

    if (id && !loading)
      openOrder(id);
  }

  handleCloseOrder() {

    const {
      clearMediaGroups,
      closeOrder,
      orders: {
        upload_action
      }
    } = this.props;

    if (upload_action.isLoading)
      return;

    closeOrder();
    clearMediaGroups();
  }

  renderOrders(orders) {

    if (!orders) return;

    const {
      clearMediaGroups,
      downloadOrderProductMedias,
      getProductMediaGroups,
      getOrders,
      orderProductMedias,
      orders: {
        orderOpen,
        loading,
        mediaGroups,
        upload_action,
      },
      uploadOrder,
      user,
    } = this.props;

    return (
      <ul className="orders-list">
        {
          orders.map((data, i) => {
            return (
              <OrderCard
                key={i}
                isLoading={loading}
                data={data}
                orderOpen={orderOpen}
                onOpenOrder={this.handleOpenOrder}
                onCloseOrder={this.handleCloseOrder}
                clearMediaGroups={clearMediaGroups}
                downloadOrderProductMedias={downloadOrderProductMedias}
                getProductMediaGroups={getProductMediaGroups}
                getOrders={getOrders}
                mediaGroups={mediaGroups}
                mode="providers"
                orderProductMedias={orderProductMedias}
                uploadAction={upload_action}
                uploadOrder={uploadOrder}
                user={user}
              />
            )
          })
        }
      </ul>
    )
  }

  render() {

    const {
      orders: {
        orderOpen,
        list,
        loading,
        upload_action
      },
    } = this.props;

    return (
      <div className="App-view Orders">
        { orderOpen &&
          <div className="order-overlay" onClick={this.handleCloseOrder}/>
        }
        { upload_action.isLoading &&
          <div className="upload-order-overlay">
            <div className="center-loader">
              <CircularProgress color="primary" size={65} />
            </div>
          </div>
        }
        <div className="page-title">
          <div className="row">
            <div className="col-xs-12 col-sm-2 col-md-4" />
            <div className="col-xs-12 col-sm-4 col-md-4">
              <h3>{translation().orders.page_title}</h3>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4 col-search">
              <SearchForm
                onSubmit={this.handleSearchOrders}
              />
            </div>
            <div className="clearfix"/>
          </div>
        </div>
        <div className="orders-wrapper">
          {
            list && list.length > 0
              ? this.renderOrders(list)
              : !loading
                ? <div className="data-empty">{translation().orders.no_order}</div>
                : ''
          }
          { loading &&
            <div className="center-loader">
              <CircularProgress color={'secondary'} size={35} />
            </div>
          }
        </div>
      </div>
    );
  }
}

Orders.propTypes = {
  clearMediaGroups: PropTypes.func.isRequired,
  closeOrder: PropTypes.func.isRequired,
  downloadOrderProductMedias: PropTypes.func.isRequired,
  getOrders: PropTypes.func.isRequired,
  getProductMediaGroups: PropTypes.func.isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape(),
  }).isRequired,
  orders: PropTypes.shape({
    upload_action: PropTypes.shape(),
    mediaGroups: PropTypes.shape(),
    orderOpen: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    list: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    paging: PropTypes.shape(),
    loading: PropTypes.bool,
  }),
  orderProductMedias: PropTypes.shape(),
  openOrder: PropTypes.func.isRequired,
  searchOrders: PropTypes.func.isRequired,
  uploadOrder: PropTypes.func.isRequired,
  user: PropTypes.shape().isRequired,
}

export default Orders;
