import { connect } from 'react-redux';

import {
  getUser, getUserSuccess, getUserFailure,
  authUser, authUserSuccess, authUserFailure
} from '../redux/actions/userActions';

import { registerDevice, unregisterDevice } from '../helpers/functions/notificationsPush';
import { setAccessToken, removeAccessToken } from '../helpers/functions/cookies';
import { fetchApi } from '../helpers/functions/api';
import { initializeGeoSettings } from '../helpers/functions/user';

import { removeSnackbar } from '../redux/actions/notificationsActions';
import { clearReducers } from '../redux/actions/appActions';

import App from '../App';

const mapStateToProps = store => ({ reduxStore: store });

const mapDispatchToProps = dispatch => {

  const signOut = () => {
    unregisterDevice();
    dispatch(clearReducers());
    removeAccessToken();
  }

  return {
    removeSnackbar() {
      dispatch(removeSnackbar());
    },
    storeAccessToken(token, expiration, callbackFunction) {
      if (!token) return;

      setAccessToken(token, expiration);

      if (callbackFunction && typeof callbackFunction === 'function')
        callbackFunction();
    },
    authUser() {
      dispatch(authUser());
    },
    authUserSuccess() {
      dispatch(authUserSuccess());
    },
    authUserFailure(error = null) {
      dispatch(authUserFailure(error));
    },
    signOut() {
      signOut();
    },
    getUser() {
      dispatch(getUser());

      fetchApi(
        "get",
        "users/self",
        null,
        null,
        null,
        user => {
          if (user) {
            initializeGeoSettings(
              user,
              () => {
                dispatch(getUserSuccess(user));
                registerDevice();
              }
            );
          }
        },
        error => {
          let errorMessage = "An error occurred, please retry later.";

          if (error.response) {
            if (error.response.status) {
              if (error.response.status === 403)
                errorMessage = "Forbidden access.";
              else if (error.response.status === 401) {
                signOut();
                errorMessage = "Unauthorized.";
              }
              else
                errorMessage = "An error occurred, please retry later.";
            }
          }
          else if (error.request)
            errorMessage = "The server does not respond, please retry later.";

          dispatch(getUserFailure(errorMessage));
        }
      );
    },
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(App);
