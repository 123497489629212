import React, { Component } from "react";
import PropTypes from "prop-types";

import Footer from "../footer/Footer";

import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import config from "../../config";
import translation from "../../translation/translation";

class Authentication extends Component {
  constructor(props) {
    super(props);

    this.state = {
      authIframeSrc: null,
      emailValue: null,
      passwordValue: null,
      emailError: null,
      passwordError: null,
    };

    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChangeEmail(e) {
    this.setState({
      emailValue: e && e.target && e.target.value ? e.target.value : null,
    });
  }

  handleChangePassword(e) {
    this.setState({
      passwordValue: e && e.target && e.target.value ? e.target.value : null,
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    const { passwordValue, emailValue } = this.state;

    const thisContext = this;

    if (passwordValue && emailValue) {
      thisContext.props.authUser();

      this.setState({
        authIframeSrc:
          config.api_url +
          "users/auth?client_id=" +
          config.api_key +
          "&email=" +
          emailValue +
          "&password=" +
          encodeURIComponent(passwordValue) +
          "&submit=1",
        passwordError: null,
        emailError: null,
      });

      document.getElementById("wm_implicit_auth_iframe").onload = () => {
        const iframeAuth = document.getElementById("wm_implicit_auth_iframe");

        try {
          const frameWindowLocation = iframeAuth.contentWindow.location.href;

          if (!frameWindowLocation) return;
          else if (
            frameWindowLocation &&
            !iframeAuth.contentWindow.location.hash
          )
            return;
        } catch (e) {
          thisContext.props.authUserFailure(
            translation().login.form.callbacks.login_failed || "Login failed."
          );
          this.setState({ authIframeSrc: null });
          console.error(e && e.stack ? e.stack : "Login iframe failed.");
          return;
        }

        const hash = iframeAuth.contentWindow.location.hash;
        const hashParams = hash.substring(1).split("&");

        for (let i = 0; i < hashParams.length; i++) {
          const hashParamsSplit = hashParams[i].split("=");
          hashParams[hashParamsSplit[0]] = hashParamsSplit[1];
        }

        const accessToken = hashParams.access_token;
        let expirationDelay = hashParams.expires_in;

        expirationDelay = expirationDelay / (60 * 60 * 24);

        if (accessToken && expirationDelay) {
          thisContext.props.storeAccessToken(
            accessToken,
            expirationDelay,
            () => {
              thisContext.props.getUser();
            }
          );

          thisContext.props.authUserSuccess();
        }
      };
    } else if (!passwordValue || !emailValue) {
      if (!passwordValue) this.setState({ passwordError: "Champs requis." });
      else this.setState({ passwordError: null });

      if (!emailValue) this.setState({ emailError: "Champs requis." });
      else this.setState({ emailError: null });
    }
  }

  render() {
    const { passwordError, emailError, authIframeSrc } = this.state;

    const { user, authentication } = this.props.reduxStore;

    return (
      <div className="Authentication">
        <div className="container auth-wrapper">
          <img
            alt="WM Actarus"
            height={100}
            src="/assets/images/logo-wish.svg"
            style={{ marginTop: 25, marginBottom: 25 }}
          />
          <h2>{translation().login.page_title}</h2>
          <div className="auth-iframe">
            <iframe
              id="wm_implicit_auth_iframe"
              title="wm_implicit_auth_iframe"
              src={authIframeSrc}
            />
          </div>
          <form onSubmit={this.handleSubmit} className="form-wrapper">
            <div className="field-wrapper">
              <TextField
                placeholder={translation().login.form.labels.email}
                label={translation().login.form.labels.email}
                type="email"
                id="user_email"
                name="user_email"
                error={emailError ? true : false}
                helperText={emailError}
                fullWidth={true}
                autoComplete="email"
                onChange={this.handleChangeEmail}
              />
            </div>
            <div className="field-wrapper">
              <TextField
                placeholder={translation().login.form.labels.password}
                label={translation().login.form.labels.password}
                type="password"
                id="user_password"
                name="user_password"
                error={passwordError ? true : false}
                helperText={passwordError}
                fullWidth={true}
                onChange={this.handleChangePassword}
              />
            </div>
            {!authentication.loading && (
              <Button
                type="submit"
                variant="outlined"
                style={{ marginTop: 10 }}
                disabled={authentication.loading || user.loading}
              >
                {translation().login.form.buttons.submit || "Log in"}
              </Button>
            )}
          </form>
          {authentication.error && (
            <div className="error-message">{authentication.error}</div>
          )}
          {(authentication.loading || user.loading) && (
            <CircularProgress style={{ marginTop: 20 }} color="primary" />
          )}
          <a
            href="https://lightshop.wall-market.com/requestPassword"
            target="_blank"
            rel="noopener noreferrer"
            className="trigger-register-form"
          >
            {translation().login.forgotten_password}
          </a>
        </div>
        <Footer />
      </div>
    );
  }
}

Authentication.propTypes = {
  reduxStore: PropTypes.shape({
    user: PropTypes.shape().isRequired,
    authentication: PropTypes.shape().isRequired,
  }),
  //eslint-disable-next-line
  authUser: PropTypes.func.isRequired,
  //eslint-disable-next-line
  authUserFailure: PropTypes.func.isRequired,
  //eslint-disable-next-line
  authUserSuccess: PropTypes.func.isRequired,
  //eslint-disable-next-line
  storeAccessToken: PropTypes.func.isRequired,
  //eslint-disable-next-line
  getUser: PropTypes.func.isRequired,
};

export default Authentication;
