const initialState = {
  loading: false,
  error: null
}

const authReducer = (state = initialState, action) => {

  switch (action.type) {
    case 'CLEAR_REDUCERS':
      return initialState;
    case 'AUTH_USER':
      return {
        ...state,
        loading: true,
        error: null
      }
    case 'AUTH_USER_SUCCESS':
      return {
        ...state,
        loading: false,
        error: null
      }
    case 'AUTH_USER_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.error
      }
    default:
      return state
  }
}

export default authReducer;
