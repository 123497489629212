import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { reset } from "redux-form";

import { fetchApi } from "../../helpers/functions/api";
import { enqueueSnackbar } from "../../redux/actions/notificationsActions";
import {
  openOrder,
  closeOrder,
  getOrders,
  getOrdersSuccess,
  getOrdersFailure,
  updateOrders,
  getProductMediaGroups,
  getProductMediaGroupsSuccess,
  getProductMediaGroupsFailure,
  clearMediaGroups,
  postOrderProductMedias,
  postOrderProductMediasSuccess,
  postOrderProductMediasFailure,
} from "../../redux/actions/ordersActions";
import config from "../../config";
import translation from "../../translation/translation";
import Orders from "../../views/orders/Orders";

const mapStateToProps = (state) => ({
  user: state.user,
  orders: state.orders,
  orderProductMedias: state.orderProductMedias,
});

const mapDispatchToProps = (dispatch) => {
  const checkBeforeSubmit = (mediasOrderArray) => {
    /**
     * Check if all media templates are set
     */
    for (let i = 0; i < mediasOrderArray.length; i++) {
      if (
        mediasOrderArray[i]["media_template_id"] !== undefined &&
        mediasOrderArray[i].media_template_id === "null"
      )
        return false;
    }
    return true;
  };

  const getProviderProducts = (user) => {
    if (config.providerProductsMatches[user.id]) {
      const productIds = config.providerProductsMatches[user.id].join("/");

      return `product_id|in|${productIds},`;
    } else if (user.level && user.level.power && user.level.power >= 200) {
      return "";
    } else {
      return 403;
    }
  };

  return {
    openOrder: (id) => {
      if (id) dispatch(openOrder(id));
    },
    closeOrder: () => {
      dispatch(closeOrder());
    },
    getOrders: (next = null, user) => {
      if (!user || !user.data || !user.data.id) {
        return;
      } else if (getProviderProducts(user.data) === 403) {
        return;
      }

      dispatch(getOrders(next));

      const query = {};

      fetchApi(
        "get",
        next
          ? next.replace(config.api_url, "")
          : "providers/all/order-products",
        next
          ? {}
          : {
              ...query,
              sort: "-start",
              filters: `${getProviderProducts(
                user.data
              )}delivery_status_id|eq|4,cancelled|nu|`,
            },
        null,
        null,
        (data, paging) => {
          next
            ? dispatch(updateOrders(data, paging))
            : dispatch(getOrdersSuccess(data, paging));
        },
        (error) => {
          dispatch(getOrdersFailure(error));
        }
      );
    },
    searchOrders: (value = "", user) => {
      if (!user || !user.data || !user.data.id) {
        return;
      } else if (getProviderProducts(user.data) === 403) {
        return;
      }

      dispatch(getOrders());

      const query = {
        search: value,
        sort: "-start",
        filters: `${getProviderProducts(
          user.data
        )}delivery_status_id|eq|4,cancelled|nu|`,
      };

      fetchApi(
        "get",
        "providers/all/order-products",
        query,
        null,
        null,
        (data, paging) => {
          dispatch(getOrdersSuccess(data, paging));
        },
        (error) => {
          dispatch(getOrdersFailure(error));
        }
      );
    },
    clearSearchForm() {
      dispatch(reset("ordersSearchForm"));
    },
    clearMediaGroups: () => {
      dispatch(clearMediaGroups());
    },
    getProductMediaGroups: (orderId, productId) => {
      if (!orderId || !productId) return;

      dispatch(getProductMediaGroups());

      fetchApi(
        "get",
        "orders/" + orderId + "/products/" + productId + "/medias/structured",
        null,
        null,
        null,
        (data) => {
          dispatch(getProductMediaGroupsSuccess(data));
        },
        (error) => {
          dispatch(getProductMediaGroupsFailure(error));
        }
      );
    },
    uploadOrder(
      ordersProductMedias,
      orderId,
      productId,
      callbackSuccess,
      callbackFailure
    ) {
      if (!ordersProductMedias || !productId) return;

      let mediasOrderArray = [];

      /**
       * Loop on all orderProductsMedias mediasTypes
       * Then get the medias for all mediasTypes and push it to the array for send to api
       */
      if (Object.keys(ordersProductMedias).length) {
        for (let mediaType in ordersProductMedias) {
          if (
            ordersProductMedias[mediaType].medias &&
            ordersProductMedias[mediaType].medias.length
          ) {
            for (
              let j = 0;
              j < ordersProductMedias[mediaType].medias.length;
              j++
            ) {
              let mediasToPost = {
                url: ordersProductMedias[mediaType].medias[j].url
                  ? ordersProductMedias[mediaType].medias[j].url
                  : null,
                media_type_id: ordersProductMedias[mediaType].id
                  ? ordersProductMedias[mediaType].id
                  : null,
                floor: ordersProductMedias[mediaType].medias[j].floor
                  ? ordersProductMedias[mediaType].medias[j].floor
                  : null,
                position: ordersProductMedias[mediaType].medias[j].position
                  ? ordersProductMedias[mediaType].medias[j].position
                  : null,
              };

              /**
               * Check if media have media template id key and if his value exists, then pass value to it
               */
              let media_template_id = null;

              if (
                ordersProductMedias[mediaType].medias[j].media_type &&
                ordersProductMedias[mediaType].medias[j].media_type
                  .has_templates
              ) {
                if (
                  ordersProductMedias[mediaType].medias[j]["media_template_id"]
                ) {
                  media_template_id =
                    ordersProductMedias[mediaType].medias[j].media_template_id;
                } else if (
                  ordersProductMedias[mediaType].medias[j]["media_template"] &&
                  ordersProductMedias[mediaType].medias[j].media_template["id"]
                ) {
                  media_template_id =
                    ordersProductMedias[mediaType].medias[j].media_template.id;
                } else if (
                  ordersProductMedias[mediaType].medias[j]["media_template"] ===
                  null
                ) {
                  media_template_id = "null";
                }

                if (media_template_id)
                  mediasToPost.media_template_id = media_template_id;
              }

              mediasOrderArray.push(mediasToPost);
            }
          }
        }
      }

      /**
       * Check if all media templates fields are filled
       */
      if (!checkBeforeSubmit(mediasOrderArray)) {
        const message = translation().orders.callbacks.fill_templates;

        dispatch(
          enqueueSnackbar({
            message,
            options: {
              variant: "warning",
            },
          })
        );

        return;
      }

      const mediasOrder = {
        medias: mediasOrderArray,
      };

      dispatch(postOrderProductMedias());

      if (orderId && productId) {
        fetchApi(
          "post",
          "orders/" + orderId + "/products/" + productId + "/medias/upload",
          null,
          mediasOrder,
          null,
          () => {
            const message = translation().orders.callbacks.medias_send_success;

            dispatch(
              enqueueSnackbar({
                message,
                options: {
                  variant: "success",
                },
              })
            );

            dispatch(postOrderProductMediasSuccess());

            if (callbackSuccess && typeof callbackSuccess === "function")
              callbackSuccess();
          },
          (error) => {
            const errorMessage = translation().orders.callbacks
              .medias_send_failure;

            if (error.response) {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : errorMessage,
                  options: {
                    variant: "error",
                  },
                })
              );
            } else {
              dispatch(
                enqueueSnackbar({
                  message: errorMessage,
                  options: {
                    variant: "error",
                  },
                })
              );
            }

            if (callbackFailure && typeof callbackFailure === "function")
              callbackFailure();

            dispatch(postOrderProductMediasFailure(error));
          }
        );
      }
    },
    downloadOrderProductMedias(
      orderId,
      orderProductId,
      query = {},
      callbackSuccess = null,
      callbackFailure = null
    ) {
      if (!orderId || !orderProductId || !query || !query.media_group_id)
        return;

      fetchApi(
        "post",
        `orders/${orderId}/products/${orderProductId}/medias/download`,
        null,
        query,
        null,
        (url) => {
          const downloadIframe = document.getElementById("frame-download");
          const iframeContainer = document.getElementById("iframes-container");

          if (downloadIframe) {
            downloadIframe.setAttribute("src", url);
          } else {
            const downloadIframe =
              '<iframe id="frame-download" title="iframe-download" width="0" height="0" style="display: none" src=' +
              url +
              " />";
            iframeContainer.innerHTML = downloadIframe;
          }

          if (callbackSuccess && typeof callbackSuccess === "function") {
            callbackSuccess();
          }
        },
        (error) => {
          const errorMessage = "Failed to download medias";

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : errorMessage,
                options: {
                  variant: "error",
                },
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: errorMessage,
                options: {
                  variant: "error",
                },
              })
            );
          }

          if (callbackFailure && typeof callbackFailure === "function") {
            callbackFailure();
          }
        }
      );
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Orders));
